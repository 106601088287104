import React, { useState, useEffect } from "react";
import { Row, Col, Select, Form, Input, Radio } from "antd";
import CompanyLogo from "../../assets/images/company-logo.png";
const { Option } = Select;
const { TextArea } = Input;

const SchoolDetail = ({ form, initialValues, onFinish, countries }) => {
  const [selectData, setSelectData] = useState();
  const [instructionLanguage, setInstructionLanguage] = useState(true);
  // const handleGlobalRankChange = (e) => {
  //   setSelectData(e.value);
  // };

  useEffect(() => {
    form.resetFields();
  }, []);

  let logo;

  if (
    initialValues.logo !== null &&
    initialValues.logo &&
    initialValues.logo.substring(0, 2) !== "77"
  ) {
    logo = (
      <img
        src={`data:image/jpeg;base64,${initialValues.logo}`}
        alt="logo"
        style={{
          width: "100px",
          justifyContent: "center",
          borderRadius: "20px",
        }}
      ></img>
    );
  } else {
    logo = (
      <img src={CompanyLogo} alt="companyLogo" style={{ width: "100px" }} />
    );
  }

  return (
    <React.Fragment>
      <Row style={{ alignItems: "center" }}>
        <Col span={24} style={{ textAlign: "center" }}>
          {logo}
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <h3 style={{ color: "#001529", fontSize: "25px", marginTop: "10px" }}>
            {initialValues.name}
          </h3>
        </Col>
      </Row>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Row style={{ marginTop: "15px" }}>
          <Col span={24}>
            <h3 style={{ color: "#001529" }}>Instruction Language: English</h3>
          </Col>
          <Col span={24}>
            <Form.Item name="selectedInstructionLanguage">
              <Radio.Group
                onChange={(e) => setInstructionLanguage(e.target.value)}
                value={instructionLanguage}
                defaultValue={initialValues.englishLanguage}
              >
                <Radio value={true}>true</Radio>
                <Radio value={false}>false</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <h4 style={{ color: "#001529" }}>Country Rank:</h4>
          </Col>
          <Col span={24}>
            <Form.Item name="selectedCountryRank">
              <Input
                defaultValue={initialValues.countryRank}
                type="number"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h4 style={{ color: "#001529" }}>Global Rank:</h4>
          </Col>
          <Col span={24}>
            <Form.Item name="selectedGlobalRank">
              <Input
                defaultValue={initialValues.globalRank}
                // onChange={handleGlobalRankChange}
                type="number"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h4 style={{ color: "#001529" }}>Score:</h4>
          </Col>
          <Col span={24}>
            <Input
              defaultValue={initialValues.score}
              readOnly
              style={{ cursor: "no-drop" }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "15px" }}>
          <Col span={24}>
            <h4 style={{ color: "#001529" }}>Country:</h4>
          </Col>
          <Col span={24}>
            <Form.Item name="selectedCountry">
              <Select
                allowClear
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                defaultValue={
                  initialValues.country !== null ? initialValues.country : ""
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {typeof countries === "object" &&
                  countries.length > 0 &&
                  countries.map((item, key) => {
                    return (
                      <Option value={item} key={key}>
                        {item}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h3 style={{ color: "#001529" }}>School Type:</h3>
          </Col>
          <Col span={24}>
            <Form.Item name="schoolType">
              <Select
                allowClear
                showSearch
                placeholder="School Type"
                optionFilterProp="children"
                defaultValue={
                  initialValues.schoolType !== null
                    ? initialValues.schoolType
                    : ""
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={"UNIVERSITY"} key={0}>
                  University
                </Option>
                <Option value={"HIGH_SCHOOL"} key={1}>
                  High School
                </Option>
                <Option value={"VOCATIONAL"} key={2}>
                  Vocational
                </Option>
                <Option value={"INSTITUTE"} key={3}>
                  Institute
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h3 style={{ color: "#001529" }}> Note:</h3>
          </Col>
          <Col span={24}>
            <Form.Item name="note">
              <TextArea rows={3} defaultValue={initialValues.note}></TextArea>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};
export default SchoolDetail;
