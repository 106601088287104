import httpService from "../helpers/httpService";
import { appStore } from "../stores/appStore";
import { TOKEN } from "../helpers/constants";

// kullanıcı yetkili bir kullanıcı mı kontrolü token üzerinden yapar
export const CurrentCustomer = async () => {
  //  TODO aşağıdaki kısmı kendi servis yapınıza göre değiştiriniz.
  appStore.dispatch("login", {
    id: 1,
    name: "Admin",
    surname: "",
    fullName: "Admin",
    token: `${TOKEN}`,
    claims: ["Reports", "Skills", "Schools", "Companies"],
  });
  return;
};

export const CreateCustomer = async (data) => {
  const response = await httpService({
    method: "post",
    url: "panorama/customers",
    data,
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Kullanıcı başarıyla eklendi!",
    },
  });

  if (!response) return false;

  return true;
};

export const UpdateCustomer = async (data) => {
  const response = await httpService({
    method: "put",
    url: "panorama/customers/update",
    data,
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Kullanıcı başarıyla güncellendi!",
    },
  });

  if (!response) return false;

  return true;
};

export const DeleteCustomer = async (customerId) => {
  const response = await httpService({
    method: "delete",
    url: "panorama/customers?customerId=" + customerId,
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Kullanıcı başarıyla silindi!",
    },
  });

  if (!response) return false;

  return true;
};

export const ActivateCustomer = async (data) => {
  const response = await httpService({
    method: "put",
    url: "panorama/customers",
    data,
    options: {
      redirectIfSuccess: "/login",
      showErrorMessageFromServer: true,
      successMessage: "Şifreniz başarıyla oluşturulmuştur.",
    },
  });

  if (!response) return false;

  return true;
};

export const PasswordResetCustomer = async (data) => {
  const response = await httpService({
    method: "put",
    url: "panorama/customer/changepassword",
    data,
    options: {
      redirectIfSuccess: "/login",
      showErrorMessageFromServer: true,
      successMessage: "Şifreniz başarıyla güncellenmiştir.",
    },
  });

  if (!response) return false;

  return true;
};

export const GetAllCustomers = async () => {
  const response = await httpService({
    method: "get",
    url: "panorama/customers/all",
    options: {
      showErrorMessageFromServer: true,
    },
  });

  if (!response) return [];

  return response.data;
};
