import { BASE_URL, TOKEN } from "../helpers/constants";
import httpService from "../helpers/httpService";

export const GetReportOfSchoolsByCountryPageable = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/school/report",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return;
  return response.data;
};

export const GetReportOfCompaniesByCountryPageable = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/company/report",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return;
  return response.data;
};
