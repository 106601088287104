import httpService from "../helpers/httpService";
import { BASE_URL, TOKEN } from "../helpers/constants";

export const GetAllSkill = async () => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/skill/all",
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return [];
  return response.data;
};

export const GetAllMainSkill = async () => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/mainSkill/all",
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  //if (!response) return [];

  return response.data;
};

export const DeleteSkill = async (data) => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/mainSkill/delete/" + data,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Skill Deleted!",
    },
  });

  if (!response) return false;

  return true;
};
export const AddSkill = async (data) => {
  console.log(data);
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/mainSkill/add",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response.data.code == 999) return response;
  return response;
};
export const UpdateSkill = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/mainSkill/update",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Skill Updated",
    },
  });
  if (!response) return false;
  return true;
};
