import React from 'react';
import { Result, Button } from 'antd';
import history from '../../../helpers/history';

const NotFound = () => {
	const handleClick = () => {
		window.location.href = '/';
	};

	return (
		<Result
			status="404"
			title="404"
			subTitle="Üzgünüz, aradığınız sayfa bulunamadı."
			extra={
				<Button type="primary" onClick={() => handleClick()}>
					Ana Sayfa
				</Button>
			}
		/>
	);
};

export default NotFound;
