import axios from "axios";
import { API_BASE_URL, BASE_URL, TOKEN } from "./constants";
import history from "./history";
import { message } from "antd";

axios.defaults.baseURL = API_BASE_URL;
axios.interceptors.response.use((response) => {
  if (response.headers.client) {
    localStorage.setItem("access-token", response.headers["access-token"]);
    localStorage.setItem("client", response.headers.client);
    localStorage.setItem("uid", response.headers.uid);
    localStorage.setItem("token-type", response.headers["token-type"]);
  }
  return response;
});

const httpService = async ({
  method,
  url,
  data,
  options,
  dispatch,
  headers,
}) => {
  if (TOKEN) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;
  }
  let response = null;
  try {
    response = await axios({
      method,
      url,
      data,
      headers,
    });

    if (options && options.successMessage) {
      message.success(options.successMessage);
    }
    if (options && options.redirectIfSuccess) {
      history.push(options.redirectIfSuccess);
    }
  } catch (err) {
    if (options && options.redirectIfFailed) {
      history.push(options.redirectIfFailed);
    }
    if (options && options.errorMessage) {
      message.error(options.errorMessage);
    }
    if (options && options.showErrorMessageFromServer) {
      if (err.response) {
        if (
          err.response.status === 403 ||
          (err.response.data &&
            (err.response.data.status === 403 ||
              err.response.data.error === "Forbidden" ||
              err.response.data.message === "Access Denied"))
          // err.response.data.code === 'SOMETHING_WENT_WRONG'
        ) {
          localStorage.removeItem("token");
          history.push("/login");
        } else {
          message.error(err.response.data.message);
        }
      } else {
        message.error(err.toString());
      }
    }
    console.log(err.response);
    throw err;
  }

  return response;

  /*
  const response = axios({
    method,
    url
  }).then(response => {

  }).catch(err => {
    if (options && options.redirectIfFailed) {
      history.push(options.redirectIfFailed)
    }
    console.log(err);
  })
  */
};

export default httpService;
