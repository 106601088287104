import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Checkbox,
  Radio,
  InputNumber,
} from "antd";
import ReactTags from "react-tag-autocomplete";
import { GetCountries } from "../../services/utils";
import {
  GetAllTags,
  GetAllCompanyTags,
  CreateTag,
  CreateCompayTag,
  DeleteCompanyTag,
} from "../../services/tag";
import CompanyLogo from "../../assets/images/company-logo.png";
const { TextArea } = Input;
const { Option } = Select;

const CompanyDetail = ({ form, initialValues, onFinish, countries }) => {
  let logo;
  if (
    initialValues.logo !== null &&
    initialValues.logo &&
    initialValues.logo.substring(0, 2) !== "77"
  ) {
    logo = (
      <img
        src={`data:image/jpeg;base64,${initialValues.logo}`}
        alt="logo"
        style={{
          width: "100px",
          justifyContent: "center",
          borderRadius: "20px",
        }}
      ></img>
    );
  } else {
    logo = (
      <img src={CompanyLogo} alt="companyLogo" style={{ width: "100px" }} />
    );
  }

  useEffect(() => {
    form.resetFields();
  }, []);

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  /*********TAGS*********/

  const reactTags = useRef();
  const [coefficient, setCoefficient] = useState(initialValues?.coefficient);
  const [internationalSelection, setInternationalSelection] = useState(true);
  const [internationalSelection2, setInternationalSelection2] = useState(
    initialValues.unlisted
  );
  const [companyTags, setCompanyTags] = useState([]);
  const [companyTagsResponseData, setCompanyTagsResponseData] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);
  useEffect(() => {
    getAllTags();
    getAllCompanyTags();
  }, []);

  useEffect(() => {}, [companyTags]);

  const getAllTags = async () => {
    const response = await GetAllTags();
    setSuggestedTags(response);
  };
  // const getChecked = async () => {
  //   const response = document.querySelector('#unlisted').getAttribute('checked');
  //   document.querySelector('#unlisted').setAttribute('value', !response);
  // };
  const getAllCompanyTags = async () => {
    const response = await GetAllCompanyTags(initialValues.id);
    let companyTagsArray = [];
    response.map((item) => {
      let tag = {
        id: item.tagId,
        name: item.tagName,
      };
      companyTagsArray.push(tag);
    });
    setCompanyTags(companyTagsArray);
    setCompanyTagsResponseData(response);
  };

  const onDelete = useCallback(
    async (tagIndex) => {
      if (tagIndex >= 0) {
        let tagToBeDeleted = companyTagsResponseData.find(
          (x) => x.tagId === companyTags[tagIndex].id
        );
        const response = await DeleteCompanyTag(tagToBeDeleted.id);
        setCompanyTags(companyTags.filter((_, i) => i !== tagIndex));
      }
    },
    [companyTags, companyTagsResponseData]
  );

  const onAddition = useCallback(
    async (newTag) => {
      let checkTag = companyTags.find((x) => x.name === newTag.name);
      if (checkTag === undefined) {
        const responseTag = await CreateTag({
          name: newTag.name,
        });
        if (responseTag) {
          setCompanyTags((companyTags) => [...companyTags, responseTag]);
          const responseCompanyTag = await CreateCompayTag({
            companyId: initialValues.id,
            tagId: responseTag.id,
          });
          if (responseCompanyTag) {
            setCompanyTagsResponseData((companyTagsResponseData) => [
              ...companyTagsResponseData,
              responseCompanyTag,
            ]);
          }
        }
      }
    },
    [companyTags, companyTagsResponseData]
  );

  // useEffect(() => {
  //   console.log(initialValues);
  // }, []);

  /*********TAGS*********/
  return (
    <React.Fragment>
      <Row style={{ alignItems: "center" }}>
        <Col span={24} style={{ textAlign: "center" }}>
          {logo}
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <h4 style={{ color: "#001529", fontSize: "25px", marginTop: "10px" }}>
            {initialValues.name}
          </h4>
        </Col>
      </Row>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Row style={{ marginTop: "15px" }}>
          <Col span={24}>
            <h5 style={{ color: "#001529" }}> International:</h5>
          </Col>
          <Col span={24}>
            <Form.Item name="internationalSelection">
              <Radio.Group
                onChange={(e) => setInternationalSelection(e.target.value)}
                value={internationalSelection}
                defaultValue={initialValues.international}
              >
                <Radio value={true}>true</Radio>
                <Radio value={false}>false</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <h5 style={{ color: "#001529" }}>Country:</h5>
          </Col>
          <Col span={24}>
            <Form.Item name="country">
              <Select
                allowClear
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                onChange={onChange}
                defaultValue={
                  initialValues.foundCountry !== null
                    ? initialValues.foundCountry
                    : ""
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {typeof countries === "object" &&
                  countries.length > 0 &&
                  countries.map((item, key) => {
                    return (
                      <Option value={item} key={key}>
                        {item}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="coefficient">
          <Col>
            <Row>
              <h5>Coefficient:</h5>
            </Row>
            <Row>
              <InputNumber
                style={{ width: "100%" }}
                controls={false}
                value={coefficient}
                onChange={(e) => setCoefficient(e)}
                onKeyDown={(event) => {
                  if (
                    !/[0-9]/.test(event.key) &&
                    event.key !== "Backspace" &&
                    event.key !== "."
                  ) {
                    event.preventDefault();
                  }
                }}
              ></InputNumber>
            </Row>
          </Col>
        </Form.Item>
        <Row>
          <Col span={24}>
            <h5 style={{ color: "#001529" }}> Tag:</h5>
          </Col>
          <Col span={24}>
            <Form.Item name="companyTags">
              <ReactTags
                ref={reactTags}
                tags={companyTags}
                suggestions={suggestedTags}
                onDelete={onDelete}
                onAddition={onAddition}
                allowNew
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h5 style={{ color: "#001529" }}> Note:</h5>
          </Col>
          <Col span={24}>
            <Form.Item name="note">
              <TextArea rows={3} defaultValue={initialValues.note}></TextArea>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h5 style={{ color: "#001529" }}> Unlisted:</h5>
          </Col>
          <Col span={24}>
            <Form.Item name="unlisted">
              <Radio.Group
                onChange={(e) => setInternationalSelection2(e.target.checked)}
                value={internationalSelection2}
                defaultValue={internationalSelection2}
              >
                <Radio value={true}>true</Radio>
                <Radio value={false}>false</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default CompanyDetail;
