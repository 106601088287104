import React from "react";
import { Spin } from "antd";

import PrivateLayout from "./layout/private";
import PublicLayout from "./layout/public";

import "./App.scss";
import { CurrentCustomer } from "./services/customer";
import { appStore } from "./stores/appStore";
import { useStoreState } from "./hooks/useStoreState";

function App() {
  const appState = useStoreState(appStore);

  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("token");

  if (!appState.user.id && token) {
    CurrentCustomer();
    return (
      <div className="loading-screen">
        <Spin size="large" tip="Yükleniyor..." />
      </div>
    );
  }

  return (
    <div className="parts-container">
      {appState.user.id ? (
        <PrivateLayout
          username={`${appState.user.name} ${appState.user.surname}`}
          claims={appState.user.claims}
        />
      ) : (
        <PublicLayout />
      )}
    </div>
  );
}

export default App;
