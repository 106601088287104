import React, { useState } from "react";
import CompanyIcon from "../../assets/svg/company.svg";
import TitleCard from "./titleCard";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import ReportOfSchoolsByCountry from "./Reports/ReportOfSchoolsByCountry";
import ReportOfCompaniesByCountry from "./Reports/ReportOfCompaniesByCountry";

const DashBoard = () => {
  const [isShowRSByCountry, setIsShowRSByCountry] = useState(true);
  const [isShowRCByCountry, setIsShowRCByCountry] = useState(true);

  return (
    <div>
      <TitleCard
        title={"Report Of Schools By Country"}
        setIsShow={() => setIsShowRSByCountry(!isShowRSByCountry)}
        isShow={isShowRSByCountry}
        children={<ReportOfSchoolsByCountry />}
      />
      <TitleCard
        title={"Report Of Companies By Country"}
        setIsShow={() => setIsShowRCByCountry(!isShowRCByCountry)}
        isShow={isShowRCByCountry}
        children={<ReportOfCompaniesByCountry />}
      />
    </div>
  );
};

const PageIcon = () => {
  return (
    <img
      src={CompanyIcon}
      alt="logo"
      style={{
        height: 20,
        width: 20,
      }}
    />
  );
};

export default {
  title: "Reports",
  icon: <PageIcon />,
  path: "/dashboard",
  claims: ["Dashboard"],
  component: DashBoard,
};
