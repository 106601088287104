import { BASE_URL, TOKEN } from "../helpers/constants";
import httpService from "../helpers/httpService";

export const AddProfession = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + `/api/v1/profession/add`,
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Profession Added!",
    },
  });
  if (!response) return false;
  return true;
};

export const GetAllProfession = async () => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + `/api/v1/profession/all`,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return false;
  return response.data;
};

export const DeleteProfession = async (id) => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + `/api/v1/profession/delete/${id}`,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Profession Deleted!",
    },
  });
  if (!response) return false;
  return true;
};

export const GetProfession = async (id) => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + `/api/v1/profession/get/${id}`,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return false;
  return response.data;
};

export const UpdateProfession = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + `/api/v1/profession/update`,
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Profession Updated!",
    },
  });
  if (!response) return false;
  return true;
};
