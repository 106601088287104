import { BASE_URL, TOKEN } from "../helpers/constants";
import httpService from "../helpers/httpService";

export const GetAllTags = async () => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/tag/",
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return [];
  return response.data;
};

export const GetAllCompanyTags = async (companyId) => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/tag/" + companyId,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return [];
  return response.data;
};

export const CreateTag = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/tag/createTag",
    data,
    options: {
      showErrorMessageFromServer: true,
    },
  });

  if (!response) return;
  return response.data.data;
};

export const CreateCompayTag = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/tag/addTagToCompany",
    data,
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Company Tag başarıyla eklendi!",
    },
  });

  if (!response) return;
  return response.data.data;
};

export const DeleteCompanyTag = async (id) => {
  const response = await httpService({
    method: "delete",
    url: BASE_URL + "/api/v1/tag/companyTag/" + id,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Company Tag Deleted!",
    },
  });
  if (!response) return false;
  return true;
};
