export const SortOrder = {
  ascend: "ASC",
  descend: "DESC",
  undefined: "",
};

export const SortObjectsAdd = (sorter) => {
  let newSortObjects = [];
  if (sorter.length > 1) {
    sorter.map((item) => {
      let sortObject = {};
      let key = item.field;
      let value = SortOrder[item.order];
      if (value !== "") {
        sortObject[key] = value;
        newSortObjects.push(sortObject);
      }
    });
  } else {
    let sortObject = {};
    let key = sorter.field;
    let value = SortOrder[sorter.order];
    if (value !== "") {
      sortObject[key] = value;
      newSortObjects.push(sortObject);
    }
  }
  return newSortObjects;
};
