import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Table,
  Input,
  Select,
  Button,
  Checkbox,
  Radio,
  Tooltip,
} from "antd";
import {
  DeleteCompany,
  GetAllCompaniesPageable,
  GetUpdateScore,
} from "../../services/company";
import CompanyDrawer from "./drawer";
import linkedinIcon from "../../assets/images/linkedin.png";
import CompanyIcon from "../../assets/svg/company.svg";
import { SortObjectsAdd } from "./../../utils";
import { GetCountries } from "../../services/utils";
import CompanyLogo from "../../assets/images/company-logo.png";
import ThreeDot from "../../assets/svg/three-dot.svg";
import UpdateScore from "../../assets/svg/update-score.svg";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";

const { Option } = Select;
const cssOverride = css`
  border-width: 4px;
`;

const Companies = () => {
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState();
  const [company, setCompany] = useState({
    data: [],
    pagination: {
      page: 0,
      size: 10,
      sort: [],
      name: null,
      coefficientIsNull: false,
      foundCountry: null,
      foundCountryIsNull: false,
      noteIsNull: false,
      note: false,
      internationalIsNull: false,
      international: null,
      scoreIsNull: false,
      score: false,
      unlistedIsNull: false,
      unlisted: null,
    },
    totalElements: 0,
  });
  const threeDotRef = useRef();

  const [drawerDetail, setDrawerDetail] = useState({
    visible: false,
    close: (refresh) => {
      if (refresh) {
        refreshAllData();
      }
      setDrawerDetail((prev) => ({
        ...prev,
        visible: false,
      }));
    },
    countries: countries,
  });

  useEffect(() => {
    getData(company.pagination);
    getCountry();
  }, []);

  useEffect(() => {
    setDrawerDetail((prev) => ({
      ...prev,
      close: (refresh) => {
        if (refresh) {
          refreshAllData();
        }
        setDrawerDetail((prev) => ({
          ...prev,
          visible: false,
        }));
      },
      countries: countries,
    }));
  }, [company.pagination]);

  window.addEventListener("click", (e) => {
    if (e.target !== threeDotRef.current) {
      const allElements = document.querySelectorAll(".sample");
      allElements.forEach((element) => {
        element.classList.remove("sample");
      });
    }
  });

  useEffect(() => {
    setColumns([
      {
        width: "100px",
        title: "Logo",
        key: "logo",
        dataIndex: "logo",
        render: (text) =>
          text !== null && text && text.substring(0, 2) !== "77" ? (
            <a>
              <img
                src={`data:image/jpeg;base64,${text}`}
                style={{ width: "50px" }}
              ></img>
            </a>
          ) : (
            <a>
              <img
                src={CompanyLogo}
                alt="companyLogo"
                style={{ width: "50px" }}
              />
            </a>
          ),
      },
      {
        width: "250px",
        title: "Company Name",
        dataIndex: "name",
        key: "name",
        sorter: {
          compare: (a, b) => a.name - b.name,
          multiple: 2,
        },
        render: (text) => <p className="text-ellipsis">{text}</p>,
      },
      {
        width: "100px",
        title: "Linkedin",
        render: (text) => (
          <p className="text-ellipsis" onClick={() => handleSubmit(text)}>
            <img
              id="linkedin"
              src={linkedinIcon}
              alt="linkedin"
              width={16}
              height={16}
            />
          </p>
        ),
      },
      {
        width: "150px",
        title: "Location",
        dataIndex: "linkedinLocation",
        key: "linkedinLocation",
      },
      {
        width: "150px",
        title: "Country",
        dataIndex: "foundCountry",
        key: "foundCountry",
      },
      {
        width: "120px",
        title: "Employees",
        dataIndex: "worker",
        sorter: {
          compare: (a, b) => a.worker - b.worker,
          multiple: 1,
        },
        key: "linkedinWorker",
      },
      {
        width: "100px",
        title: "Scored Cv",
        dataIndex: "numberOfScoredCv",
        key: "numberOfScoredCv",
      },
      {
        width: "120px",
        title: "Coefficient",
        key: "coefficient",
        dataIndex: "coefficient",
        sorter: {
          compare: (a, b) => a.score - b.score,
          multiple: 1,
        },
      },
      {
        width: "150px",
        title: "Industry",
        key: "industry",
        dataIndex: "industry",
      },

      {
        width: "100px",
        title: "Score",
        key: "score",
        dataIndex: "score",
        sorter: {
          compare: (a, b) => a.score - b.score,
          multiple: 3,
        },
      },
      {
        width: "100px",
        title: "",
        dataIndex: "id",
        render: (dataIndex) => (
          <div
            ref={threeDotRef}
            onClick={(e) => {
              handleCellClick(e, dataIndex);
            }}
            className="refresh"
            data-index={dataIndex}
          >
            <img src={ThreeDot} alt="ThreeDot" />
            {
              <div className="dropdownRefresh" style={{ display: "none" }}>
                <ul>
                  <li>
                    <button
                      className="score-btn"
                      onClick={() => {
                        updateScore(dataIndex);
                      }}
                    >
                      <img
                        src={UpdateScore}
                        alt="update"
                        className="score-icon"
                      />
                      Score
                    </button>
                  </li>
                </ul>
              </div>
            }
          </div>
        ),
      },
    ]);
  }, []);

  const handleCellClick = (event, dataIndex) => {
    event.stopPropagation();
    const allElements = document.querySelectorAll(
      '.refresh:not([data-index="' + dataIndex + '"])'
    );
    allElements.forEach((element) => {
      element.classList.remove("sample");
    });
    event.currentTarget.classList.toggle("sample");
  };

  const updateScore = async (scoreId) => {
    setIsLoading(true);
    const response = await GetUpdateScore(scoreId);
    document.querySelector(
      'tr[data-row-key="' + scoreId + '"] > td:nth-last-child(2)'
    ).innerHTML = response.score;
    document.querySelector(
      'tr[data-row-key="' + scoreId + '"] > td:nth-last-child(5)'
    ).innerHTML = response.numberOfScoredCv;
    setIsLoading(false);
  };

  const getCountry = async () => {
    const response = await GetCountries();
    if (response) {
      setCountries(response);
    }
  };

  const getData = async (params) => {
    setIsLoading(true);
    const response = await GetAllCompaniesPageable(params);
    setCompany((prevState) => ({
      ...prevState,
      data: response.data,
      pagination: {
        ...prevState.pagination,
        page: response.pageNumber,
        size: params.size,
        sort: params.sort,
      },
      totalElements: response.totalElements,
    }));
    setIsLoading(false);
  };

  const setCompanyPagination = (propsName, value) => {
    setCompany((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        [propsName]: value,
      },
    }));
  };

  const handleTableChange = (pagination, {}, sorter, {}) => {
    window.scrollTo(0, 0);
    let newSortObjects = SortObjectsAdd(sorter);
    getData({
      ...company.pagination,
      page: pagination.current - 1,
      size: pagination.pageSize,
      sort: newSortObjects,
    });
  };

  const refreshAllData = () => {
    getData(company.pagination);
  };

  const handleCompanyDetail = async (record) => {
    setDrawerDetail((prev) => ({
      ...prev,
      visible: true,
      type: "detail",
      id: record.id,
      initialValues: record,
      title: `Company Edit`,
      handleDeleteCompany: () => {
        handleDeleteCompany(record);
      },
      countries: countries,
    }));
  };

  const handleDeleteCompany = (record) => {
    DeleteCompany(record.id).then(() => {
      refreshAllData();
      setDrawerDetail((prev) => ({
        ...prev,
        visible: false,
      }));
    });
  };

  const { data, totalElements, pagination } = company;

  const handleSubmit = (text) => {
    console.log(text);
    text = text.linkedinNumericRef ? text.linkedinNumericRef : text.linkedinRef;
    const win = window.open(
      "https://www.linkedin.com/company/" + text,
      "_blank"
    );
    win.focus();
  };

  const handleSearch = () => {
    getData({
      ...company.pagination,
      page: 0,
    });
  };

  const handleClearFilters = () => {
    setCompany((prevState) => ({
      ...prevState,
      pagination: {
        page: 0,
        size: 10,
        sort: [],
        name: null,
        coefficientIsNull: false,
        foundCountry: null,
        foundCountryIsNull: false,
        noteIsNull: false,
        note: false,
        internationalIsNull: false,
        international: null,
        scoreIsNull: false,
        score: false,
        unlistedIsNull: false,
        unlisted: null,
      },
    }));
    getData({
      page: 0,
      size: 10,
      sort: [],
      name: null,
      coefficientIsNull: false,
      foundCountry: null,
      foundCountryIsNull: false,
      noteIsNull: false,
      note: false,
      internationalIsNull: false,
      international: null,
      scoreIsNull: false,
      score: false,
      unlistedIsNull: false,
      unlisted: null,
    });
  };

  return (
    <div>
      <Row className="tables">
        <div className="filter-view">
          <div className="filter-entries">
            <div style={{ width: "180px", marginRight: "15px" }}>
              <Input
                placeholder="name"
                size="large"
                value={company.pagination.name}
                onChange={(e) => setCompanyPagination("name", e.target.value)}
              />
            </div>
            <div style={{ width: "180px", marginRight: "15px" }}>
              <Select
                allowClear
                size="large"
                showSearch
                placeholder="country"
                optionFilterProp="children"
                value={company.pagination.foundCountry}
                onChange={(value) =>
                  setCompanyPagination("foundCountry", value)
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {typeof countries === "object" &&
                  countries.length > 0 &&
                  countries.map((item, key) => {
                    return (
                      <Option value={item} key={key}>
                        {item}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div>
              <Checkbox
                onChange={(e) => {
                  setCompanyPagination("note", e.target.checked);
                }}
                checked={company.pagination.note}
              >
                Note
              </Checkbox>
            </div>
            <div className="checked-area">
              <div className="checked-area-title">International</div>
              <Tooltip
                placement="topRight"
                title="Sadece 1 seçim yapabilirsiniz"
              >
                <Radio.Group
                  onChange={(e) => {
                    setCompanyPagination("internationalIsNull", false);
                    setCompanyPagination("international", e.target.value);
                  }}
                  value={company.pagination.international}
                >
                  <Radio value={true}>true</Radio>
                  <Radio value={false}>false</Radio>
                </Radio.Group>
                <Checkbox
                  onChange={(e) => {
                    setCompanyPagination("international", null);
                    setCompanyPagination(
                      "internationalIsNull",
                      e.target.checked
                    );
                  }}
                  checked={company.pagination.internationalIsNull}
                >
                  null
                </Checkbox>
              </Tooltip>
            </div>
          </div>
          <div className="filter-checked">
            <div className="checked-area">
              <div className="checked-area-title"> Is Null ?</div>
              <Checkbox
                onChange={(e) =>
                  setCompanyPagination("foundCountryIsNull", e.target.checked)
                }
                checked={company.pagination.foundCountryIsNull}
              >
                Country
              </Checkbox>
              <Checkbox
                onChange={(e) =>
                  setCompanyPagination("noteIsNull", e.target.checked)
                }
                checked={company.pagination.noteIsNull}
              >
                Note
              </Checkbox>
              <Checkbox
                onChange={(e) =>
                  setCompanyPagination("scoreIsNull", e.target.checked)
                }
                checked={company.pagination.scoreIsNull}
              >
                Score
              </Checkbox>
              <Checkbox
                checked={company.pagination.coefficientIsNull}
                onChange={(e) =>
                  setCompanyPagination("coefficientIsNull", e.target.checked)
                }
              >
                Coefficient
              </Checkbox>
            </div>
            <div className="checked-area">
              <div className="checked-area-title">Unlisted</div>
              <Tooltip
                placement="topRight"
                title="Sadece 1 seçim yapabilirsiniz"
              >
                <Radio.Group
                  onChange={(e) => {
                    setCompanyPagination("unlistedIsNull", false);
                    setCompanyPagination("unlisted", e.target.value);
                  }}
                  value={company.pagination.unlisted}
                >
                  <Radio value={true}>true</Radio>
                  <Radio value={false}>false</Radio>
                </Radio.Group>
                <Checkbox
                  onChange={(e) => {
                    setCompanyPagination("unlisted", null);
                    setCompanyPagination("unlistedIsNull", e.target.checked);
                  }}
                  checked={company.pagination.unlistedIsNull}
                >
                  null
                </Checkbox>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="filter-button">
          <Button
            style={{ width: "120px" }}
            type="default"
            size="large"
            htmlType="submit"
            onClick={handleClearFilters}
          >
            Clear
          </Button>
          <Button
            style={{ width: "120px" }}
            type="primary"
            size="large"
            htmlType="submit"
            onClick={handleSearch}
          >
            Filter
          </Button>
        </div>
        {columns && (
          <Table
            scroll={{ x: "%100" }}
            columns={columns}
            dataSource={data}
            style={{
              width: "100%",
              textAlignLast: "center",
              cursor: "pointer",
            }}
            onChange={handleTableChange}
            rowKey="id"
            footer={() => "Showing of " + totalElements + " entries "}
            tableLayout="fixed"
            pagination={{
              total: totalElements,
              current: pagination.page + 1,
              size: pagination.size,
              pageSize: pagination.size,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (event.target.id === "linkedin") {
                  } else {
                    handleCompanyDetail(record);
                  }
                },
              };
            }}
          />
        )}
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ClipLoader
              color={"#03283a"}
              loading={isLoading}
              size={60}
              css={cssOverride}
            />
          </div>
        )}
        <CompanyDrawer {...drawerDetail} />
      </Row>
    </div>
  );
};

const PageIcon = () => {
  return (
    <img
      src={CompanyIcon}
      alt="logo"
      style={{
        height: 20,
        width: 20,
      }}
    />
  );
};

export default {
  title: "Companies",
  icon: <PageIcon />,
  path: "/Companies",
  claims: ["Companies"],
  component: Companies,
};
