import "antd/dist/antd.css";
import moment from "moment";
import React from "react";
import { createRoot } from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { BASE_PATH } from "./helpers/constants";
import * as serviceWorker from "./serviceWorker";
const root = createRoot(document.getElementById('root')); 
window.moment = moment;

root.render(
  <Router basename={BASE_PATH}>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
