import React from "react";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

const TitleCard = ({ setIsShow, isShow, children, title }) => {
  return (
    <>
      <div className="toogle-div" onClick={setIsShow}>
        <div className="report-title">{title}</div>
        {isShow ? (
          <UpOutlined className="arrow-icon" />
        ) : (
          <DownOutlined className="arrow-icon" />
        )}
      </div>
      {isShow && children}
    </>
  );
};

export default TitleCard;
