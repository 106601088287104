import React, { useState, useEffect, useRef } from "react";
import { GetAllSkill, UpdateSkill } from "../../../services/skill";
import "bootstrap/dist/css/bootstrap.css";
import { Col, Row, Card } from "react-bootstrap";
import { Input, Spin, Checkbox, Button, message, Empty } from "antd";

const AddMultiSkills = (props) => {
  const { setIsButtonClicked, recordDetail, subskills } = props;
  const searchedSkillRef = useRef("");
  const [searchResponse, setSearchResponse] = useState(null);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [showUnchecked, setShowUnchecked] = useState(false);
  const [showChecked, setShowChecked] = useState(false);
  const { Search } = Input;
  const [checkedList, setCheckedList] = useState([]);
  const [initialSkills, setInitialSkills] = useState([]);

  useEffect(() => {
    const sortedSkills = recordDetail.skillList
      .map((item) => ({
        id: item.skillId,
        name: item.skillName,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    setInitialSkills(sortedSkills);
    setCheckedList(sortedSkills);
  }, [recordDetail]);

  const updateSkillObject = [];
  const modalDetail = {
    id: null,
    name: null,
    skillList: [
      {
        skillId: null,
        skillName: null,
      },
    ],
    valueCoefficient: "",
  };

  const handleSaveButton = () => {
    if (checkedList.length) {
      let c = checkedList;
      c.map((item) => {
        let a = {
          skillId: item.id,
          skillName: item.name,
        };
        updateSkillObject.push(a);
      });

      modalDetail.skillList = updateSkillObject;
      modalDetail.name = recordDetail.name;
      modalDetail.id = recordDetail.id;
      modalDetail.valueCoefficient = recordDetail.valueCoefficient;
      UpdateSkill(modalDetail);
      setTimeout(function () {
        window.location.reload(false);
      }, 1500);
    } else {
      message.error("Main skill must contain at least one subskill!");
    }
  };

  const handleOnSubmit = () => {
    setIsAllChecked(false);
    setShowUnchecked(false);
    setShowChecked(false);
    const searchedSkill = searchedSkillRef.current.input.value;
    if (searchedSkill.length > 1) {
      const searchedList = subskills.filter((item) =>
        item.name.toLowerCase().includes(searchedSkill.toLowerCase())
      );
      setSearchResponse(searchedList);
    }
    if (searchedSkill == "c") {
      const searchedList = subskills.filter(
        (item) => item.name[0].toLowerCase() == "c"
      );
      setSearchResponse(searchedList);
    } else if (searchedSkill.length < 2) {
      message.error("Search term must be longer than one character.");
    }
  };

  const handleShowUnchecked = () => {
    setShowChecked(false);
    setShowUnchecked(!showUnchecked);
  };
  const handleShowChecked = () => {
    setShowUnchecked(false);
    setShowChecked(!showChecked);
  };

  const [newCheckedItems, setNewCheckedItems] = useState([]);

  const handleAllChecked = () => {
    if (isAllChecked) {
      const filteredList = checkedList.filter((item) => {
        return !newCheckedItems.some(
          (searchItem) => searchItem.name === item.name
        );
      });
      setNewCheckedItems([]);
      setCheckedList(filteredList);
      setIsAllChecked(false);
    } else if (searchResponse !== null) {
      const newItems = searchResponse.filter(
        (item) =>
          !checkedList.some((existingItem) => existingItem.name === item.name)
      );
      setNewCheckedItems(newItems);
      const updatedList = [...checkedList, ...newItems];
      setCheckedList(updatedList);
      setIsAllChecked(true);
    } else {
      setIsAllChecked(true);
    }
  };

  const handleChangeCheck = (e) => {
    if (checkedList.some((item) => item.name == e.name)) {
      // remove item from list
      setCheckedList((prevList) =>
        prevList.filter((item) => item.name !== e.name)
      );
    } else {
      // add item to list
      const newItem = { id: e.id, name: e.name };
      setCheckedList([...checkedList, newItem]);
    }
  };

  const listSkills = () => {
    let uncheckedList = [];
    if (searchResponse !== null) {
      uncheckedList = searchResponse.filter((item) =>
        checkedList.every((checkedItem) => checkedItem.name !== item.name)
      );
    }

    if (showUnchecked) {
      return uncheckedList.map((item) => (
        <div key={item.id}>
          <Checkbox
            checked={checkedList.includes(item)}
            onChange={() => handleChangeCheck(item)}
          >
            {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
          </Checkbox>
        </div>
      ));
    } else if (showChecked) {
      return checkedList.map((item) => (
        <div key={item.id}>
          <Checkbox
            checked={checkedList.some(
              (checklistItem) => checklistItem.name === item.name
            )}
            onChange={() => handleChangeCheck(item)}
          >
            {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
          </Checkbox>
        </div>
      ));
    } else {
      if (searchResponse !== null && searchResponse.length > 0) {
        return searchResponse.map((item) => (
          <div key={item.id}>
            <Checkbox
              checked={checkedList.some(
                (checklistItem) => checklistItem.name === item.name
              )}
              onChange={() => handleChangeCheck(item)}
            >
              {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
            </Checkbox>
          </div>
        ));
      } else if (searchResponse == null) {
        return checkedList.map((item) => (
          <div key={item.id}>
            <Checkbox
              checked={checkedList.some(
                (checklistItem) => checklistItem.name === item.name
              )}
              onChange={() => handleChangeCheck(item)}
            >
              {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
            </Checkbox>
          </div>
        ));
      } else {
        return [];
      }
    }
  };

  return (
    <>
      <Row className="col-12">
        <h5
          style={{
            fontSize: 26,
            marginBottom: 30,
            marginLeft: 10,
          }}
        >
          {recordDetail.name}
        </h5>
      </Row>

      <Row
        className="col-12"
        style={{
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Col className="col-4">
          <Search
            placeholder="Search"
            allowClear
            size="large"
            onSearch={handleOnSubmit}
            onPressEnter={handleOnSubmit}
            ref={searchedSkillRef}
          />
        </Col>
        <Row className="col-5">
          <Col className="col-6">
            <Row>
              <Checkbox checked={showUnchecked} onChange={handleShowUnchecked}>
                Show Unchecked
              </Checkbox>
            </Row>
            <Row>
              <Checkbox checked={showChecked} onChange={handleShowChecked}>
                Show Checked
              </Checkbox>
            </Row>
          </Col>
          <Col className="col-6">
            <Checkbox checked={isAllChecked} onChange={handleAllChecked}>
              Check All
            </Checkbox>
          </Col>
        </Row>

        <Row className="col-2" style={{ justifyContent: "flex-end" }}>
          <Col className="col-6 ">
            <Button
              type="primary"
              disabled={
                JSON.stringify(initialSkills) === JSON.stringify(checkedList)
              }
              size="large"
              onClick={handleSaveButton}
            >
              Update
            </Button>
          </Col>
          <Col className="col-6 ">
            <Button
              type="default"
              size="large"
              onClick={() => setIsButtonClicked(false)}
            >
              Close
            </Button>
          </Col>
        </Row>
      </Row>

      <Row
        className="col-12 g-0 justify-content-between "
        style={{
          backgroundColor: "#f1f1f1",
          padding: 5,
          fontSize: 20,
          paddingLeft: 20,
          borderRadius: 5,
          marginBottom: 20,
          marginTop: 20,
          border: "groove",
        }}
      >
        <Col>Subskills</Col>
        {searchResponse && (
          <Col
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <h6
              style={{
                fontSize: 16,
                margin: 0,
                paddingRight: 10,
                fontWeight: "normal",
                fontStyle: "italic",
              }}
            >
              Found {searchResponse.length} results for "
              {searchedSkillRef.current.input.value}"
            </h6>
          </Col>
        )}
      </Row>

      <Row className="row" style={{ minWidth: "100%" }}>
        {listSkills().length > 0 ? (
          listSkills().map((item, index) => (
            <Col key={index} className="col-3 mb-2 d-flex">
              <Card style={{ width: "100%" }}>
                <Card.Body>{item}</Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <div className="justify-content-center mt-5 w-100">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </Row>
    </>
  );
};
export default AddMultiSkills;
