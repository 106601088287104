import { BASE_URL, TOKEN } from "../helpers/constants";
import httpService from "../helpers/httpService";

export const GetAllCountries = async () => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/country/all",
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return [];
  return response.data;
};
export const UpdateCountry = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/country/update",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
    options: {
      successMessage: "Country Updated!",
      errorMessage: "Country Update Failed!",
    },
  });
  if (!response) return false;
  return true;
};
export const GetCountryDetails = async (id) => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + `/api/v1/country/detail/${id}`,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return [];
  return response.data;
};

export const PostCountryDetails = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/country/detail",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
  });
  if (!response) return false;
  return response.data;
}

export const PostCountryDetailsCreate = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/country/create",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
  });
  if (!response) return false;
  return response.data;
}
