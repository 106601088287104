import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { ActivateCustomer, PasswordResetCustomer } from '../../../services/customer';

const PasswordCreate = () => {
	const params = new URLSearchParams(window.location.search);
	const [ form ] = Form.useForm();
	const [ name, setName ] = useState(null);
	const [ code, setCode ] = useState(null);
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		const activationCode = params.get('ActivationCode') || params.get('PasswordResetCode');
		const fullName = params.get('fullname');
		setCode(activationCode);
		setName(fullName);
	}, []);

	const handleSubmit = async (values) => {
		setLoading(true);
		if (params.get('PasswordResetCode')) {
			await PasswordResetCustomer({
				password_reset_code: code,
				new_password: values.password
			});
		} else {
			await ActivateCustomer({
				activation_code: code,
				password: values.password
			});
		}
		setLoading(false);
	};

	const validatePassword = (rule, value, callback) => {
		if (value !== form.getFieldValue('password')) {
			callback('Girdiğiniz parolalar birbiriyle eşleşmiyor.');
		} else {
			callback();
		}
	};

	return (
		<React.Fragment>
			<div className="wrapper-loggin">
				<div className="col-login col-login_left">
					<h2>Parola Belirleme</h2>

					<Form layout="vertical" form={form} onFinish={handleSubmit}>
						<Form.Item>
							{name && (
								<p>
									Sayın <strong>{name}</strong>, aşağıdaki formu kullanarak parolanızı
									belirleyebilirsiniz.
								</p>
							)}
						</Form.Item>
						<Form.Item
							label=""
							name="password"
							rules={[
								{ required: true, message: 'Lütfen parolanızı giriniz.' },
								{ min: 6, message: 'Parolanız en az 6 karakter olmalıdır!' }
							]}
						>
							<Input.Password
								placeholder="Parola"
								size="large"
								prefix={<LockOutlined />}
								iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
							/>
						</Form.Item>
						<Form.Item
							label=""
							name="confirm_password"
							rules={[
								{ required: true, message: 'Lütfen parolanızı tekrar giriniz.' },
								{ validator: validatePassword }
							]}
						>
							<Input.Password
								placeholder="Parola (tekrar)"
								size="large"
								prefix={<LockOutlined />}
								iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
							/>
						</Form.Item>

						{params.get('ActivationCode') && (
							<React.Fragment>
								<Form.Item
									label=""
									name="personal_data"
									valuePropName="checked"
									defaultValue={false}
									rules={[
										{
											validator: (rule, value, callback) =>
												!value
													? callback(
															'Bu servisten faydalanabilmeniz için kişisel verilerim hakkındaki "Aydınlatma Metni" sözleşmeyi kabul etmelisiniz.'
														)
													: callback()
										}
									]}
								>
									<Checkbox>
										Kişisel verilerim hakkındaki{' '}
										<a href="/agreements/Gini_KVK_Aydinlatma_Metni.html" target="_blank">
											Aydınlatma Metni
										</a>{' '}
										kapsamında verilerimin işlenmesine açık rıza veriyorum.
									</Checkbox>
								</Form.Item>
								<Form.Item
									label=""
									name="agreement"
									valuePropName="checked"
									defaultValue={false}
									rules={[
										{
											validator: (rule, value, callback) =>
												!value
													? callback(
															'Bu servisten faydalanabilmeniz için "Kullanım Koşulları" nı kabul etmelisiniz.'
														)
													: callback()
										}
									]}
								>
									<Checkbox>
										<a href="/agreements/Gini_Kullanim_Kosullari.html" target="_blank">
											Kullanım Koşulları
										</a>nı okudum ve kabul ediyorum.
									</Checkbox>
								</Form.Item>
							</React.Fragment>
						)}
						<Form.Item>
							<Form.Item className="btn-login">
								<Button type="primary" size="large" htmlType="submit" loading={loading}>
									Kaydet
								</Button>
							</Form.Item>
						</Form.Item>

						<p className="help-desk">
							Yardıma mı ihtiyacınız var? Bize ulaşın:{' '}
							<a href={'mailto:destek@ginifinance.com'}>destek@ginifinance.com</a>
						</p>
					</Form>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PasswordCreate;
