import React, { useState, useEffect } from "react";
import { GetReportOfSchoolsByCountryPageable } from "../../../../services/report";
import { SortObjectsAdd } from "../../../../utils";
import { Row, Table, Input, Button } from "antd";

const ReportOfSchoolsByCountry = () => {
  const [schoolsColumns, setSchoolsColumns] = useState([]);
  const [reportOfSchoolsByCountry, setReportOfSchoolsByCountry] = useState({
    data: [],
    pagination: {
      page: 0,
      size: 10,
      sort: [],
      country: "",
    },
    totalElements: 0,
  });

  useEffect(() => {
    //Tablo kolonları
    setSchoolsColumns([
      {
        title: "Country",
        dataIndex: "name",
        key: "name",
        sorter: {
          compare: (a, b) => a.name - b.name,
          multiple: 4,
        },
      },
      {
        title: "Schools",
        dataIndex: "schoolCount",
        key: "schoolCount",
        sorter: {
          compare: (a, b) => a.schoolCount - b.schoolCount,
          multiple: 3,
        },
      },
      {
        title: "Ranked",
        dataIndex: "globalRank",
        key: "globalRank",
        sorter: {
          compare: (a, b) => a.globalRank - b.globalRank,
          multiple: 2,
        },
      },
      {
        title: "Note Added",
        dataIndex: "noteCount",
        key: "noteCount",
        sorter: {
          compare: (a, b) => a.noteCount - b.noteCount,
          multiple: 1,
        },
      },
    ]);
  }, []);

  useEffect(() => {
    getReportOfSchoolsByCountry(reportOfSchoolsByCountry.pagination);
  }, []);

  const getReportOfSchoolsByCountry = async (params) => {
    const response = await GetReportOfSchoolsByCountryPageable(params);

    setReportOfSchoolsByCountry((prevState) => ({
      ...prevState,
      data: response.data,
      pagination: {
        ...prevState.pagination,
        page: response.pageNumber,
        size: params.size,
        sort: params.sort,
      },
      totalElements: response.totalElements,
    }));
  };

  const handleTableChange = (pagination, {}, sorter, {}) => {
    window.scrollTo(0, 0);
    let newSortObjects = SortObjectsAdd(sorter);
    getReportOfSchoolsByCountry({
      ...reportOfSchoolsByCountry.pagination,
      page: pagination.current - 1,
      size: pagination.pageSize,
      sort: newSortObjects,
    });
  };

  const handleFilter = () => {
    getReportOfSchoolsByCountry({
      ...reportOfSchoolsByCountry.pagination,
      page: 0,
    });
  };

  const { data, pagination, totalElements } = reportOfSchoolsByCountry;
  return (
    <Row className="tables">
      <div className="filter-entries" style={{ marginBottom: "20px" }}>
        <div style={{ width: "180px", marginRight: "20px" }}>
          <Input
            placeholder="country"
            size="large"
            value={reportOfSchoolsByCountry.pagination.country}
            onChange={(e) =>
              setReportOfSchoolsByCountry((prevState) => ({
                ...prevState,
                pagination: {
                  ...prevState.pagination,
                  country: e.target.value,
                },
              }))
            }
          />
        </div>
        <Button
          style={{ width: "120px" }}
          type="primary"
          size="large"
          htmlType="submit"
          onClick={handleFilter}
        >
          Filter
        </Button>
      </div>
      <Table
        scroll={{ x: "%100" }}
        columns={schoolsColumns}
        dataSource={data}
        style={{
          width: "100%",
          textAlignLast: "center",
          cursor: "pointer",
        }}
        rowKey="country"
        tableLayout="fixed"
        onChange={handleTableChange}
        pagination={{
          current: pagination.page + 1,
          pageSize: pagination.size,
          total: totalElements,
        }}
      />
    </Row>
  );
};

export default ReportOfSchoolsByCountry;
