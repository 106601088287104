import React, { useState, useEffect } from "react";
import {
  Row,
  Table,
  Input,
  Tooltip,
  Button,
  Popconfirm,
  Form,
  Select,
  message,
} from "antd";
import {
  DashboardOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  GetAllMainSkill,
  GetAllSkill,
  UpdateSkill,
  DeleteSkill,
  AddSkill,
} from "../../services/skill";
import ReactModal from "react-modal";
import { WithContext as ReactTags } from "react-tag-input";
import SkillIcon from "../../assets/svg/skills.svg";
import AddMultiSkills from "./AddMultiSkills";

const { Option } = Select;

const Skills = () => {
  const [recordDetail, setRecordDetail] = useState({
    category: null,
    id: null,
    name: null,
    skillList: [
      {
        id: null,
        mainId: null,
        skillId: null,
        skillName: null,
      },
    ],
    valueCoefficient: "",
  });
  const addSkillObject = [];
  const updateSkillObject = [];
  const [skill, setSkill] = useState([]);

  const [mainSkill, setMainSkill] = useState([
    {
      id: "",
      name: "",
      category: "",
      skillList: [
        {
          id: "",
          skillId: "",
          skillName: "",
          mainId: "",
        },
      ],
      valueCoefficient: "",
    },
  ]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState([]);

  const modalDetail = {
    id: null,
    name: null,
    skillList: [
      {
        skillId: null,
        skillName: null,
      },
    ],
    valueCoefficient: "",
  };

  const addSkill = {
    name: "",
    skillList: [
      {
        skillId: "",
        skillName: "",
      },
    ],
    valueCoefficient: "",
  };

  const getAllSkill = async () => {
    const response = await GetAllSkill();
    if (response) {
      const sortedSkills = response.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setSkill(sortedSkills);
    }

    setIsLoaded(true);
  };

  const getMainSkill = async () => {
    const response = await GetAllMainSkill();
    if (response) {
      setMainSkill(response);
    }
  };

  useEffect(() => {
    getMainSkill();
    getAllSkill();
  }, []);

  const addTag = async (event) => {
    let c = tag;
    c.map((item) => {
      let a = {
        skillId: item.id,
        skillName: item.text,
      };
      addSkillObject.push(a);
    });
    addSkill.name = state.value;
    addSkill.skillList = addSkillObject;
    addSkill.valueCoefficient = selectedValues;
    //console.log(addSkill);
    if (addSkill.name != undefined && addSkill.skillList.length != 0) {
      const response = await AddSkill(addSkill);
      //console.log(response);
      if (response.data.code == 999) {
        alert(response.data.message + "\nMain skill name must be unique.");
      } else {
        closeModal();
        setState([]);
        setTag([]);
        event.preventDefault();
        window.location.reload(true);
      }
    }
  };
  const updateTags = () => {
    if (tags.length) {
      let c = tags;
      c.map((item) => {
        let a = {
          skillId: item.id,
          skillName: item.text,
        };
        updateSkillObject.push(a);
      });
      modalDetail.skillList = updateSkillObject;
      modalDetail.name = state2.value;
      modalDetail.id = recordDetail.id;

      if (typeof selectedValues2 == "object") {
        modalDetail.valueCoefficient = selectedValues2.value;
      } else {
        modalDetail.valueCoefficient = selectedValues2;
      }

      UpdateSkill(modalDetail);
      closeModal();
      setTimeout(function () {
        window.location.reload(false);
      }, 2500);
    } else {
      message.error("Main skill must contain at least one subskill!");
    }
  };

  const handleSkillDetail = (record) => {
    setState2({
      value: record.name,
    });
    setSelectedValues2({
      value: record.valueCoefficient,
    });
    let bb = [];
    record.skillList.map((item) => {
      let aa = {
        id: item.skillId,
        text: item.skillName,
      };
      bb.push(aa);
    });
    setRecordDetail(record);
    setTags(bb);
    openModal2();
  };
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const handleAddMultiSkills = () => {
    setIsButtonClicked(true);
    closeModal2();
  };
  const handleDeleteSkill = (record) => {
    DeleteSkill(record.id).then(() => {
      window.location.reload(false);
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "1",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Subskills",
      dataIndex: "skillList",
      render: (skillList) => skillList.map((a) => a.skillName).join(" - "),
      key: "2",
    },
    {
      title: "Value Coefficient",
      dataIndex: "valueCoefficient",
      key: "3",
    },
    {
      title: "Action",
      dataIndex: "edit",
      key: "edit",
      size: 30,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Button.Group size="middle">
              <Tooltip placement="top" title="Add Skills">
                <Button
                  title="View"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    handleSkillDetail(record);
                    handleAddMultiSkills();
                    console.log(record);
                  }}
                />
              </Tooltip>
              <Tooltip placement="top" title="Detail">
                <Button
                  title="View"
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => {
                    handleSkillDetail(record);
                  }}
                />
              </Tooltip>
              <Tooltip placement="top" title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete this record?"
                  onConfirm={() => handleDeleteSkill(record)}
                  okText="Yes"
                  cancelText="No!"
                >
                  <Button type="default" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </Button.Group>
          </React.Fragment>
        );
      },
    },
  ];

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setState([]);
    setTag([]);
    setIsOpen(false);
    setSelectedValues("");
  }
  function openModal2() {
    setIsOpen2(true);
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  const customStyles = {
    overlay: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItem: "center",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "650px",
      minHeight: "550px",
      borderColor: "#1890ff",
      maxHeight: "550px",
    },
  };

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const handleDelete = (i) => {
    setTag(tag.filter((tag, index) => index !== i));
  };
  const handleDelete2 = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };
  const handleAddition = (tagg) => {
    setTag([...tag, tagg]);
  };
  const handleAddition2 = (tag) => {
    setTags([...tags, tag]);
  };
  const suggestions = skill.map((skill) => {
    return {
      id: skill.id,
      text: skill.name,
    };
  });

  const [state, setState] = useState([{ value: "" }]);
  const [state2, setState2] = useState([{ value: "" }]);
  const [selectedValues, setSelectedValues] = useState("");
  const [selectedValues2, setSelectedValues2] = useState("");

  const handleChange = (event) => {
    setState({ value: event.target.value });
  };

  const handleChange2 = (event) => {
    setState2({ value: event.target.value });
  };

  const options = [];

  for (let i = 1; i <= 100; i++) {
    options.push(i);
  }

  return (
    <>
      {!isButtonClicked ? (
        <div>
          <Row className="tables">
            <div className="filter-button">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                onClick={openModal}
                hidden={!isLoaded}
              >
                Add Main Skills
              </Button>

              <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <form>
                  <div>
                    <Input
                      placeholder="Name"
                      size="large"
                      className="customInput"
                      style={{ border: "none", borderBottom: "1px solid" }}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ border: "none" }}>
                    <ReactTags
                      tags={tag}
                      suggestions={suggestions}
                      delimiters={delimiters}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      inputFieldPosition="top"
                      minQueryLength={2}
                      placeholder={"Subskill"}
                    />
                  </div>
                  <div>
                    <Select
                      placeholder="Value Coefficient"
                      size="large"
                      className="valueCoefficient"
                      onSelect={(value) => {
                        setSelectedValues(value);
                      }}
                    >
                      {options.map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <div>
                      <Button onClick={closeModal} className="skills-button">
                        Close
                      </Button>
                    </div>
                    <div>
                      <Button
                        disabled={
                          tag?.length === 0 ||
                          state?.values?.length === 0 ||
                          !selectedValues
                        }
                        onClick={addTag}
                        className="skills-button"
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                  <div>
                    <label>{selectedValues ? selectedValues : ""}</label>
                  </div>
                </form>
              </ReactModal>
              <ReactModal
                isOpen={modalIsOpen2}
                onRequestClose={closeModal2}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div>
                  <Input
                    placeholder="Name"
                    size="large"
                    className="customInput"
                    style={{ border: "none", borderBottom: "1px solid" }}
                    defaultValue={state2.value}
                    onChange={handleChange2}
                  />
                </div>
                <div style={{ border: "none" }}>
                  <ReactTags
                    tags={tags}
                    suggestions={suggestions}
                    delimiters={delimiters}
                    handleDelete={handleDelete2}
                    handleAddition={handleAddition2}
                    inputFieldPosition="top"
                    minQueryLength={2}
                    placeholder={"Subskill"}
                  />
                </div>
                <div>
                  <Select
                    defaultValue={selectedValues2}
                    size="large"
                    className="valueCoefficient"
                    onChange={(value) => {
                      setSelectedValues2(value);
                    }}
                  >
                    {options.map((option) => (
                      <Option key={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <Button onClick={closeModal2} className="skills-button">
                      Close
                    </Button>
                  </div>
                  <div>
                    <Button
                      disabled={state2.value === ""}
                      onClick={updateTags}
                      className="skills-button"
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </ReactModal>
            </div>
            <Table
              style={{ width: "100%", textAlignLast: "center" }}
              rowKey="id"
              loading={!isLoaded}
              columns={columns}
              dataSource={mainSkill}
            />
          </Row>
        </div>
      ) : (
        <Row>
          <AddMultiSkills
            setIsButtonClicked={setIsButtonClicked}
            recordDetail={recordDetail}
            subskills={skill}
          />
        </Row>
      )}
    </>
  );
};
const PageIcon = () => {
  return (
    <img
      src={SkillIcon}
      alt="logo"
      style={{
        height: 20,
        width: 20,
      }}
    />
  );
};

export default {
  title: "Skills",
  icon: <PageIcon />,
  path: "/Skills",
  claims: ["Skills"],
  component: Skills,
};
