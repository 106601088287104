import React, { useState, useEffect } from "react";
import CountryIcon from "../../assets/images/planet-earth.png";
import { Row, Table } from "antd";
import { GetAllCountries } from "../../services/country";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import CountryDrawer from "./drawer";
import CountryInfo from "./CountryInfo/countryInfo";

const Countries = () => {
  const cssOverride = css`
    border-width: 4px;
  `;
  const [isOpen, setIsOpen] = useState(false);

  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countriesState, setCountriesState] = useState([]);
  const [drawerDetail, setDrawerDetail] = useState({
    visible: false,
    close: (refresh) => {
      if (refresh) {
        getCountry();
      }
      setDrawerDetail((prev) => ({
        ...prev,
        visible: false,
      }));
    },
  });

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = async () => {
    setIsLoading(true);
    const response = await GetAllCountries();
    if (response) {
      setCountriesState(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setColumns([
      {
        width: "400px",
        title: "Country",
        dataIndex: "enName",
        key: "1",
        sorter: (a, b) => a.enName.localeCompare(b.enName),
        sortDirections: ["descend"],
        render: (text) => <p className="text-ellipsis">{text}</p>,
      },
      {
        width: "150px",
        title: "Salary Coefficient",
        dataIndex: "salaryCoefficient",
        key: "2",
        render: (text) =>
          text === null ? "" : <p className="text-ellipsis">{text}</p>,
      },
    ]);
  }, []);

  const handleCountriesDetail = async (record) => {
    setDrawerDetail((prev) => ({
      ...prev,
      visible: true,
      type: "detail",
      id: record.id,
      initialValues: record,
      title: `Country Edit`,
    }));
  };

  return (
    <>
      {isOpen ? (
        <CountryInfo
          setIsOpen={setIsOpen}
          countryValues={drawerDetail.initialValues}
        />
      ) : (
        <Row className="tables">
          {columns && (
            <Table
              scroll={{ x: "%100" }}
              columns={columns}
              dataSource={countriesState}
              style={{
                width: "100%",
                textAlignLast: "center",
                cursor: "pointer",
              }}
              rowKey="id"
              tableLayout="fixed"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (event.target.id === "linkedin") {
                    } else {
                      handleCountriesDetail(record);
                      setIsOpen(true);
                    }
                  },
                };
              }}
            />
          )}
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClipLoader
                color={"#03283a"}
                loading={isLoading}
                size={60}
                css={cssOverride}
              />
            </div>
          )}
          {/* <CountryDrawer {...drawerDetail} /> */}
        </Row>
      )}
    </>
  );
};

const PageIcon = () => {
  return (
    <img
      src={CountryIcon}
      alt="logo"
      style={{
        height: 20,
        width: 20,
        filter: "brightness(0) invert(1)",
      }}
    />
  );
};

export default {
  title: "Countries",
  icon: <PageIcon />,
  path: "/Countries",
  claims: ["Countries"],
  component: Countries,
};
