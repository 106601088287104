import { BASE_URL, TOKEN } from "../helpers/constants";
import httpService from "../helpers/httpService";

export const GetCountries = async () => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/country/",
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return [];
  return response.data;
};
