import { Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";

const TextModal = ({ open, closeModal, textValue, setTextValue }) => {
  const [initialText, setInitialText] = useState("");

  useEffect(() => {
    setInitialText(textValue);
  }, [open]);

  return (
    <div>
      <Modal centered open={open} footer={false} closable={false}>
        <div>
          <TextArea
            style={{ minHeight: 200, fontSize: 16 }}
            value={initialText}
            onChange={(e) => setInitialText(e.target.value)}
          ></TextArea>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              gap: 10,
              marginTop: 10,
            }}
          >
            <Button onClick={() => closeModal()}> Cancel</Button>
            <Button
              onClick={() => {
                setTextValue(initialText);
                closeModal();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TextModal;
