import React, { useEffect, useMemo, useState } from "react";
import icon from "../../assets/images/resume.png";
import { Badge, Checkbox, DatePicker, Table } from "antd";
import { GetLogs } from "../../services/log";
import { BASE_URL } from "../../helpers/constants";
import moment from "moment";

const Applications = () => {
  const [data, setData] = useState([]);
  const [isShowError, setIsShowError] = useState(false);
  const [date, setDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [successRate, setSuccessRate] = useState(null);

  const columns = [
    { title: "", dataIndex: "rowNumber", key: "rowNumber", width: "1%" },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      width: "29%",
    },
    {
      title: "Encoded File Name (aws)",
      dataIndex: "encodedFileName",
      key: "encodedFileName",
      width: "30%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) =>
        moment(a.date, "DD.MM.YYYY HH:mm:ss") -
        moment(b.date, "DD.MM.YYYY HH:mm:ss"),
      sortDirections: ["ascend", "descend", "ascend"],
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "20%",
    },
  ];

  const findSuccessRate = () => {
    const errors = data?.filter(
      (item) => item?.status?.props?.status === "error"
    ).length;
    setErrorCount(errors);
    const successRate = ((fileCount - errors) / fileCount) * 100;
    setSuccessRate("%" + successRate.toFixed(2));
  };

  const isTest = BASE_URL === "https://test-app.talentscore.io/server";

  const getAllLogs = async () => {
    setIsLoading(true);
    const response = await GetLogs();
    if (response) {
      setData(
        response.map((item, index) => ({
          key: item.encodedFileName,
          rowNumber: index + 1,
          fileName: item.cvId ? (
            <a
              href={`https://paysight.${isTest ? "info" : "co"}/tr/edit?id=${
                item.cvId
              }`}
              target="_blank"
              title="Click to edit this CV"
            >
              {item.fileName}
            </a>
          ) : (
            item.fileName
          ),
          encodedFileName: item.encodedFileName,
          date: new Date(item.modifiedDate).toLocaleString(),
          status: (
            <Badge
              status={item.cvId ? "success" : "error"}
              text={item.note}
            ></Badge>
          ),
        }))
      );
      setFileCount(response.length);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllLogs();
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      findSuccessRate();
    }
  }, [data]);

  const applyFilters = () => {
    let filteredData = [...data];
    if (isShowError) {
      filteredData = filteredData.filter(
        (item) => item.status.props.status === "error"
      );
    }
    if (date) {
      filteredData = filteredData.filter(
        (item) => item.date.split(" ")[0] === date
      );
    }
    return filteredData;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          marginBottom: 10,
          justifyContent: "space-between",
        }}
      >
        <div>
          <Checkbox
            checked={isShowError}
            onChange={() => setIsShowError(!isShowError)}
            style={{ padding: 10 }}
          >
            Show Errors Only
          </Checkbox>
          <DatePicker
            format={"DD.MM.YYYY"}
            onChange={(date, dateString) => setDate(dateString)}
            size="middle"
          ></DatePicker>
        </div>
        <div style={{ alignContent: "center", padding: 10 }}>
          Success Rate: <span style={{ color: "blue" }}>{successRate}</span>
        </div>
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={applyFilters()}
        pagination={true}
      ></Table>
      <p>
        File count:<span style={{ color: "blue" }}> {fileCount} </span>
      </p>
      <p>
        Success:
        <span style={{ color: "green" }}> {fileCount - errorCount} </span>
      </p>
      <p>
        Error:<span style={{ color: "red" }}> {errorCount} </span>
      </p>
    </div>
  );
};

export const ApplicationsIcon = () => {
  return (
    <img
      src={icon}
      alt="logo"
      style={{
        height: 20,
        width: 20,
        filter: "brightness(0) invert(1)",
      }}
    />
  );
};
export default {
  title: "Applications",
  icon: <ApplicationsIcon />,
  path: "/Applications",
  claims: ["Applications"],
  component: Applications,
};
