import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./login";
import ForgotPassword from "./forgot-password";
import PasswordCreate from "./create-password";

import "./index.scss";

import AppLogo from "../../assets/images/app-logo.png";
import { Agreement } from "../../modules/Agreement";
import NotFound from "../../modules/Common/NotFound";

const PublicLayout = () => {
  return (
    <div className="login-page">
      <div className="container">
        <div className="logo-container">
          <h1>
            <span className="mainH1">TalentScore</span> <span>&#x0007C;</span>{" "}
            <img src={AppLogo} alt="Gini Finance" />
          </h1>
        </div>
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/create-password" element={<PasswordCreate />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route
            path="/"
            element={
              <React.Fragment>
                <Login />
                <p className="copy">
                  &copy; 2021 TalentScore <span>&#x0007C;</span> Gini Talent. Tüm
                  hakları Saklıdır.&nbsp;&nbsp;
                  <Agreement />
                </p>
              </React.Fragment>
            }
          ></Route>
          <Route element={<NotFound />}></Route>
        </Routes>
      </div>
    </div>
  );
};

export default PublicLayout;
