import React, { useEffect, useState } from "react";
import CountryIcon from "../../assets/images/planet-earth.png";
import { Button, Card, List, Select, Tooltip } from "antd";
import { GetAllCountries } from "../../services/country";
import "./index.css";
import TextModal from "./textModal";
import { EditOutlined } from "@ant-design/icons";
import {
  CreateGoodCountry,
  GetGoodCompany,
  UpdateGoodCountry,
} from "../../services/goodCountry";

const GoodCountries = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [allCountries, setAllCountries] = useState([]);
  const [technology, setTechnology] = useState("");
  const [culture, setCulture] = useState("");
  const [security, setSecurity] = useState("");
  const [worldOrder, setWorldOrder] = useState("");
  const [planetAndClimate, setPlanetAndClimate] = useState("");
  const [prosperity, setProsperity] = useState("");
  const [health, setHealth] = useState("");
  const [environment, setEnvironment] = useState("");
  const [education, setEducation] = useState("");
  const [traffic, setTraffic] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    const getCountries = async () => {
      const response = await GetAllCountries();
      setAllCountries(response);
    };
    getCountries();
  }, []);

  const getData = async (country) => {
    const res = await GetGoodCompany(country);
    console.log(res);
    setCulture(res.cultureDetail);
    setEducation(res.educationDetail);
    setEnvironment(res.greenDetail);
    setHealth(res.healthAndWellBeingDetail);
    setId(res.id);
    setSecurity(res.internationalPeaceAndSecurityDetail);
    setPlanetAndClimate(res.planetAndClimateDetail);
    setProsperity(res.prosperityAndEqualityDetail);
    setTechnology(res.scienceAndTechnologyDetail);
    setTraffic(res.trafficDetail);
    setWorldOrder(res.worldOrderDetail);
  };

  const saveData = async (data) => {
    const response = data?.id
      ? await UpdateGoodCountry(data)
      : await CreateGoodCountry(data);
    console.log(response);

    setSelectedCountry(null);
  };

  useEffect(() => {
    selectedCountry && getData(selectedCountry);
  }, [selectedCountry]);

  const onSave = () => {
    const data = {
      country: selectedCountry,
      cultureDetail: culture,
      educationDetail: education,
      greenDetail: environment,
      healthAndWellBeingDetail: health,
      id: id,
      internationalPeaceAndSecurityDetail: security,
      planetAndClimateDetail: planetAndClimate,
      prosperityAndEqualityDetail: prosperity,
      scienceAndTechnologyDetail: technology,
      trafficDetail: traffic,
      worldOrderDetail: worldOrder,
    };

    saveData(data);
  };

  const criterias = [
    {
      title: "Technology",
      value: technology,
      setter: setTechnology,
    },
    { title: "Culture", value: culture, setter: setCulture },
    {
      title: "Security",
      value: security,
      setter: setSecurity,
    },
    {
      title: "World Order",
      value: worldOrder,
      setter: setWorldOrder,
    },
    {
      title: "Planet & Climate",
      value: planetAndClimate,
      setter: setPlanetAndClimate,
    },
    {
      title: "Prosperity",
      value: prosperity,
      setter: setProsperity,
    },
    {
      title: "Health",
      value: health,
      setter: setHealth,
    },
    { title: "Environment", value: environment, setter: setEnvironment },
    {
      title: "Education",
      value: education,
      setter: setEducation,
    },

    { title: "Traffic", value: traffic, setter: setTraffic },
  ];

  const handleItemClick = (item) => {
    setSelectedDetail(item);
    setIsModalOpen(true);
  };

  return (
    <div
      style={{
        display: "grid",
        paddingInline: 20,
      }}
    >
      <Button
        disabled={!selectedCountry}
        type="primary"
        style={{ width: 100, height: 40, justifySelf: "end" }}
        onClick={onSave}
      >
        Save
      </Button>
      <div
        style={{
          fontSize: 22,
          fontWeight: 600,
          marginBottom: 50,
          width: "30%",
          display: "flex",
        }}
      >
        <span> Country: </span>
        <Select
          showSearch
          options={allCountries.map((item, index) => ({
            label: item.enName,
            value: item.enName,
          }))}
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e)}
          size="large"
          style={{ marginLeft: 10 }}
        ></Select>
      </div>
      {selectedCountry && (
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={criterias}
          renderItem={(item) => (
            <List.Item>
              <Card
                style={{
                  paddingInline: 10,
                  borderRadius: 16,
                  fontSize: 16,
                  height: 150,
                  backgroundColor: item.value
                    ? "rgb(0, 119, 0,0.75)"
                    : "rgb(223, 0, 0,0.75)",
                }}
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: 18,
                      color: "white",
                    }}
                  >
                    <span>{item.title}</span>
                    <Tooltip placement="top" title="Detail">
                      <Button
                        onClick={() => handleItemClick(item)}
                        title="View"
                        type="default"
                        style={{ borderRadius: 16 }}
                        icon={<EditOutlined />}
                      />
                    </Tooltip>
                  </div>
                }
              >
                <p
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    margin: 0,
                    color: "white",
                  }}
                >
                  {item.value}
                </p>
              </Card>
            </List.Item>
          )}
        />
      )}
      <TextModal
        open={isModalOpen}
        closeModal={setIsModalOpen}
        textValue={selectedDetail?.value}
        setTextValue={selectedDetail?.setter}
      />
    </div>
  );
};

const PageIcon = () => {
  return (
    <img
      src={CountryIcon}
      alt="logo"
      style={{
        height: 20,
        width: 20,
        filter: "brightness(0) invert(1)",
      }}
    />
  );
};

export default {
  title: "Good Countries",
  icon: <PageIcon />,
  path: "/good-countries",
  claims: ["Good Countries"],
  component: GoodCountries,
};
