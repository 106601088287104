import React, { useCallback, useEffect } from "react";
import { Drawer, Form, Tooltip, Button, Popconfirm } from "antd";
import CompanyDetail from "./detail";
import { UpdateCompany } from "../../services/company";
import { DeleteOutlined } from "@ant-design/icons";

const CompanyDrawer = ({
  title,
  visible,
  type,
  id,
  close,
  initialValues,
  handleDeleteCompany,
  countries,
}) => {
  const [form] = Form.useForm();
  const Component = () => {
    switch (type) {
      case "detail":
        return (
          <CompanyDetail
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            countries={countries}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {}, [form]);

  const onFinish = useCallback(
    async (values) => {
      if (!values.selectedTier) {
        values.selectedTier = initialValues.tierLevel;
      }

      if (values.internationalSelection === undefined) {
        values.internationalSelection = initialValues.international;
      }
      console.log(values);
      if (type === "detail") {
        await UpdateCompany({
          id: initialValues.id,
          linkedinRef: initialValues.linkedinRef,
          note: values.note,
          foundCountry: values.country,
          international: values.internationalSelection,
          unlisted: values.unlisted,
          coefficient: values.coefficient,
        });
      }
      close(true);
    },
    [type, id]
  );
  return (
    <Drawer
      title={title}
      data={initialValues}
      width={350}
      onClose={() => close()}
      open={visible}
      bodyStyle={{ paddingBottom: 10 }}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              textAlign: "left",
            }}
          >
            <Tooltip placement="top" title="Delete">
              <Popconfirm
                title="Are you sure you want to delete this record?"
                onConfirm={handleDeleteCompany}
                okText="Yes"
                cancelText="No!"
              >
                <Button
                  type="default"
                  icon={<DeleteOutlined />}
                  style={{ marginLeft: "20px" }}
                />
              </Popconfirm>
            </Tooltip>
          </div>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={() => close()} style={{ marginRight: 8 }}>
              Close
            </Button>
            <Button onClick={form.submit} type="primary">
              Save
            </Button>
          </div>
        </div>
      }
    >
      <Component />
    </Drawer>
  );
};

export default CompanyDrawer;
