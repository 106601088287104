import vegemite from "vegemitex";
import { TOKEN } from "../helpers/constants";
import history from "../helpers/history";

let localStorageUser = localStorage.getItem("user");
if (!localStorageUser || localStorageUser === "undefined") {
  localStorageUser = undefined;
}

export const appStore = vegemite({
  user: JSON.parse(localStorageUser || "{}") || {
    id: "",
    name: "",
    surname: "",
    fullName: "",
    claims: [],
    token: `${TOKEN}`,
  },
});

appStore.on("login", (state, user) => {
  state.user = user;
  localStorage.setItem("user", JSON.stringify(user));
});

appStore.on("logout", (state, user) => {
  state.user = {};
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  history.push("/login");
  window.location.reload();
});
