import React, { useState, useEffect } from "react";
import { Form, Input, Button, Radio, message } from "antd";
import { Link } from "react-router-dom";
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { TokenService } from "../../../services/security";
import { CurrentCustomer } from "../../../services/customer";
import Recaptcha from "react-recaptcha";
import { recaptchaSiteKey } from "../../../helpers/constants";


const Login = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isCaptcha, setIsCaptcha] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [error, setError] = useState("");

  const verifyCallback = (response) => {
    if (response) {
      setIsCaptchaVerified(true);
    }
  };

  const handleSubmit = async (values) => {
    setError("");
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    try {
      if (isCaptcha) {
        if (isCaptchaVerified) {
          const response = await TokenService(values);
          if (response.data.code === 200) {
            await CurrentCustomer();
          } else {
            setError(response.data.message);
          }
        } else {
          message.error("Lütfen Doğrulama yapınız.");
        }
      } else {
        const response = await TokenService(values);
        if (response.data.code === 200) {
          await CurrentCustomer();
        } else {
          setIsCaptcha(true);
          setError(response.data.message);
        }
      }
    } catch (error) {
      setIsCaptchaVerified(true);
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="wrapper-loggin">
        <div className="col-login col-login_left">
          <h2>Hesabınıza giriş yapın:</h2>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Form.Item
              label=""
              name="email"
              rules={[
                { required: true, message: "Lütfen bu alanı doldurunuz." },
              ]}
            >
              <Input
                placeholder="e-Posta Adresi"
                size="large"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              label=""
              name="password"
              rules={[
                { required: true, message: "Lütfen bu alanı doldurunuz." },
              ]}
            >
              <Input.Password
                placeholder="Parola"
                size="large"
                prefix={<LockOutlined />}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            {isCaptcha ? (
              <Form.Item style={{ marginBottom: 40 }}>
                <p style={{ color: "red" }}> {error} </p>
                <Recaptcha
                  sitekey={recaptchaSiteKey}
                  verifyCallback={() => setIsCaptchaVerified(true)}
                />
              </Form.Item>
            ) : null}

            <Form.Item>
              <Link to="/forgot-password" className="login-form-forgot">
                Parolamı Unuttum
              </Link>
              <p className="help-desk">
                Yardıma mı ihtiyacınız var? Bize ulaşın:{" "}
                <a href={"mailto:destek@ginifinance.com"}>
                  destek@ginifinance.com
                </a>
              </p>
            </Form.Item>
            <Form.Item className="btn-login">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={loading}
              >
                Giriş Yap
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="col-login col-login_right">
          <h3>
            Gini Talent is a global staffing and recruitment agency in software
            development business and recruits the right talent for what the
            position requires.
          </h3>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
