import Dashboard from "./Dashboard";
import Skills from "./Skills";
import Schools from "./Schools";
import Companies from "./Companies";
import Countries from "./Countries";
import GoodCountries from "./GoodCountries";
import Applications from "./Applications";
import Professions from "./Professions";

export default [
  Dashboard,
  Skills,
  Schools,
  Companies,
  Countries,
  GoodCountries,
  Applications,
  Professions,
];
