import { BASE_URL, TOKEN } from "../helpers/constants";
import httpService from "../helpers/httpService";

export const GetAllCompanies = async () => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/company/",
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return [];
  return response.data;
};

export const GetSearchedCompanies = async (param) => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/company/search/" + param,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return [];
  return response.data;
};

export const GetUpdateScore = async (id) => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/company/score/" + id,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return [];
  return response.data;
};

export const GetAllCompaniesPageable = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/company/pageable",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return;
  return response.data;
};

export const UpdateCompany = async (data) => {
  console.log("data", data);
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/company/update",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Company Updated!",
    },
  });
  if (!response) return false;
  return true;
};
export const DeleteCompany = async (data) => {
  const response = await httpService({
    method: "delete",
    url: BASE_URL + "/api/v1/company/" + data,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
      successMessage: "Company Deleted!",
    },
  });
  if (!response) return false;
  return true;
};
