import { BASE_URL } from "../helpers/constants";
import history from "../helpers/history";
import httpService from "../helpers/httpService";

export const TokenService = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/auth/admin/login",
    headers: {},
    data,
    options: {
      showErrorMessageFromServer: true,
    },
  });

  if (response?.data?.code === 200) {
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("refresh_token", response.data.token);
    history.push("/dashboard");
    window.location.reload();
    return response;
  } else return response;
};

export const PasswordReset = async (data) => {
  const response = await httpService({
    method: "get",
    url: "panorama/customer/sendresetlink/" + data.email,
    options: {
      showErrorMessageFromServer: true,
    },
  });
  return response;
};
