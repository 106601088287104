import React, { useEffect, useState } from "react";
import icon from "../../assets/images/job-seeker.png";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Spin, Table, Tooltip } from "antd";
import SkillsModal from "./skillsModal";
import "./professions.scss";
import { GetAllMainSkill } from "../../services/skill";
import { DeleteProfession, GetAllProfession } from "../../services/profession";

const Professions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [mainSkills, setMainSkills] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState(null);

  const getMainSkills = async () => {
    const response = await GetAllMainSkill();
    if (response) {
      setMainSkills(
        response.map((item) => ({
          mainSkillId: item.id,
          mainSkillName: item.name,
        }))
      );
    }
  };

  const getAllProfessions = async () => {
    const response = await GetAllProfession();
    if (response) {
      setDataSource(
        response?.map((profession) => ({
          key: profession.id,
          id: profession.id,
          name: profession.name,
          mainSkills: profession.mainSkillList,
        }))
      );
    }
  };

  const deleteProfession = async (id) => {
    setIsLoading(true);
    const response = await DeleteProfession(id);
    if (response) {
      getAllProfessions();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getMainSkills();
      await getAllProfessions();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const handleClick = (action, record) => {
    action === "update" ? setIsUpdate(true) : setIsUpdate(false);
    setSelectedProfession(record);
    setIsOpen(!isOpen);
  };

  const columns = [
    {
      title: "Profession",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "ascend",
      width: "25%",
    },
    {
      title: "Main Skills",
      dataIndex: "mainSkills",
      key: "mainSkills",
      render: (skillList) =>
        skillList.map((skill) => skill.mainSkillName).join(" - "),
      width: "50%",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "25%",
      key: "action",
      render: (index, record) => {
        return (
          <React.Fragment>
            <Button.Group size="middle">
              <Tooltip placement="top" title="Detail">
                <Button
                  onClick={() => handleClick("update", record)}
                  title="View"
                  type="primary"
                  icon={<EditOutlined />}
                />
              </Tooltip>
              <Tooltip placement="top" title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete this record?"
                  okText="Yes"
                  cancelText="No!"
                  onConfirm={() => deleteProfession(record.id)}
                >
                  <Button type="default" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </Button.Group>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <div style={{ height: "100%" }}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Row justify="end" style={{ margin: 30 }}>
            <Button
              type="primary"
              size="large"
              onClick={() => handleClick("add")}
            >
              Add Profession
            </Button>
          </Row>

          <Table
            columns={columns}
            dataSource={dataSource}
            style={{ width: "100%" }}
            className="table-cells"
          />

          <SkillsModal
            isUpdate={isUpdate}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            mainSkills={mainSkills}
            selectedProfession={selectedProfession}
          />
        </>
      )}
    </div>
  );
};

export const ProfessionsIcon = () => {
  return (
    <img
      src={icon}
      alt="logo"
      style={{
        height: 20,
        width: 20,
        filter: "brightness(0) invert(1)",
      }}
    />
  );
};

export const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin />
    </div>
  );
};

export default {
  title: "Professions",
  icon: <ProfessionsIcon />,
  path: "/Professions",
  claims: ["Professions"],
  component: Professions,
};
