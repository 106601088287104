import {
  Button,
  DatePicker,
  Input,
  Row,
  Col,
  Table,
  Typography,
  Select,
  notification,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  PostCountryDetails,
  PostCountryDetailsCreate,
} from "../../../services/country";
import "./countryInfo.scss";
import { GetAllCurrency } from "../../../services/currency";
import AgencyModal from "./agencyModal";

const CountryInfo = ({ setIsOpen, countryValues }) => {
  const [salaryCoef, setSalaryCoef] = useState(countryValues.salaryCoefficient);
  const [currency, setCurrency] = useState(countryValues.countryCurrency);
  const [selectedDate, setSelectedDate] = useState(moment());
  const countryName = countryValues.enName;
  const [tableData, setTableData] = useState([]);
  const [sortedTableData, setSortedTableData] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countryRate, setCountryRate] = useState(countryValues.countryRate);
  const [incomeTax, setIncomeTax] = useState(countryValues.personalIncomeTax);
  const [agencyList, setAgencyList] = useState(countryValues.agencyList);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const arr = tableData.sort((a, b) => a.field.localeCompare(b.field));
    const sortedArr = [
      arr[8], //Minimum Wage
      arr[9], //Minimum Wage Local
      arr[0], //Average Wage
      arr[1], //Average Wage Local
      arr[2], //Cost of Living
      arr[3], //Cost of Living Local
      arr[4], //Inflation Rate MoM
      arr[5], //Inflation Rate YoY
      arr[10], //Unemployment Rate
      arr[6], //Job Openings
      arr[7], //Job Seekers
    ];
    setSortedTableData(sortedArr);
  }, [tableData]);

  const getCurrencies = () => {
    GetAllCurrency().then((res) => {
      const list = res.map((item) => ({
        value: item.code,
        label: item.code,
      }));
      setCurrencyList(list);
    });
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  const columns = [
    {
      title: " ",
      dataIndex: "field",
      key: "field",
    },
    ...months.map((month) => ({
      title: month.slice(0, 3),
      dataIndex: month.toLowerCase(),
      key: month.toLowerCase(),
      render: (text, record) => (
        <Input
          title={record?.monthDtoList[month.toLowerCase()]}
          className="pt-1 pb-1"
          value={record?.monthDtoList[month.toLowerCase()]}
          onChange={(e) =>
            onInputChange(record, month.toLowerCase(), e.target.value)
          }
        />
      ),
    })),
  ];

  const inputLimiter = (e, minValue, maxValue) => {
    const inputValue = parseFloat(e.target.value) || 0;
    const limitedValue = Math.max(minValue, Math.min(maxValue, inputValue));
    return limitedValue;
  };

  const onInputChange = (record, month, value) => {
    const newData = tableData.map((item) => {
      if (item.countryDetailId === record.countryDetailId) {
        return {
          ...item,
          monthDtoList: {
            ...item.monthDtoList,
            [month]: value,
          },
        };
      } else {
        return item;
      }
    });
    setTableData(newData);
  };

  const saveData = async () => {
    const obj = {
      countryId: countryValues.id,
      salaryCoefficient: Number(salaryCoef),
      year: selectedDate.format("YYYY"),
      details: tableData,
      countryCurrency: currency,
      personalIncomeTax: incomeTax?.toString(),
      countryRate: countryRate,
      agencyList: agencyList,
    };
    try {
      const response = await PostCountryDetailsCreate(obj);
      if (response) {
        setTableData(response.details);
      }
      notification.success({ message: "Country Updated!" });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert("Error while saving country details");
    }
  };

  const getCountryDetails = async (data) => {
    try {
      const response = await PostCountryDetails(data);
      if (response) {
        setTableData(response.details);
      } else {
        alert("Error while fetching country details");
      }
    } catch (error) {
      alert("Error while fetching country details");
    }
  };

  const changeDate = (date) => {
    setSelectedDate(date);
    const obj = {
      countryId: countryValues.id,
      year: date.format("YYYY"),
    };
    getCountryDetails(obj);
  };

  useEffect(() => {
    const obj = {
      countryId: countryValues.id,
      year: moment().format("YYYY"),
    };
    getCountryDetails(obj);
  }, []);

  return (
    <div>
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 style={{ color: "#001529" }}>{countryName}</h2>
        <div>
          <Button type="primary" className="save-button" onClick={saveData}>
            Save
          </Button>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
        </div>
      </Row>
      <hr />
      <Row justify={"space-between"}>
        <Col>
          <Row>
            <div className="d-flex me-4">
              <Typography.Title
                level={5}
                className="m-0 me-1 d-flex align-items-center"
              >
                Country Coefficient:
              </Typography.Title>

              <Input
                style={{ width: 80 }}
                size="middle"
                type="number"
                className="rounded-2"
                placeholder="Coef"
                value={salaryCoef}
                onChange={(e) => setSalaryCoef(e.target.value)}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-", "."].includes(evt.key) &&
                  evt.preventDefault()
                }
              />
            </div>
            <Row className="me-4">
              <Typography.Title
                level={5}
                className="m-0 me-1 d-flex align-items-center"
              >
                Currency:
              </Typography.Title>
              <Col>
                <Select
                  className="selectBox"
                  showSearch
                  value={currency}
                  onChange={(e) => setCurrency(e)}
                  options={currencyList}
                ></Select>
              </Col>
            </Row>
            <Row className="me-4">
              <Typography.Title
                level={5}
                className=" m-0 me-1 d-flex align-items-center"
              >
                Country Rate:
              </Typography.Title>
              <Col>
                <Input
                  style={{ width: 80 }}
                  type="number"
                  className="rounded-2"
                  placeholder="Rate"
                  value={countryRate}
                  onChange={(e) => setCountryRate(inputLimiter(e, 0, 5))}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                />
              </Col>
            </Row>
            <div className="d-flex align-items-center">
              <p style={{ fontWeight: 600 }} className="m-0 me-1">
                Per. Income Tax:
              </p>
              <span>
                <Input
                  style={{ width: 90 }}
                  type="number"
                  className="custom-input"
                  placeholder="Tax"
                  value={incomeTax}
                  addonAfter="%"
                  onChange={(e) => setIncomeTax(inputLimiter(e, 0, 100))}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                />
              </span>
            </div>
            <Button
              type="default"
              className="rounded-4 ms-4"
              onClick={() => setIsModalOpen(true)}
            >
              Agencies
            </Button>
          </Row>
        </Col>
        <Col className="d-flex justify-content-end">
          <DatePicker
            inputReadOnly
            style={{ marginBottom: 20, borderRadius: 6, width: 100 }}
            picker="year"
            size="middle"
            onChange={(date) => changeDate(date)}
            allowClear={false}
            value={selectedDate}
          />
        </Col>
      </Row>

      <Row>
        <Table
          className="w-100"
          columns={columns}
          dataSource={sortedTableData}
          pagination={false}
        />
      </Row>

      <AgencyModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        agencyList={agencyList}
        setAgencyList={setAgencyList}
        countryValues={countryValues}
      />
    </div>
  );
};

export default CountryInfo;
