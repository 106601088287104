import { BASE_URL, TOKEN } from "../helpers/constants";
import httpService from "../helpers/httpService";

export const GetAllSchool = async () => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/school",
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return [];
  return response.data;
};

export const GetAllSchoolPagable = async (pageNumber, pageSize) => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + `/api/v1/school/pageable?p=${pageNumber}&s=${pageSize}`,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return null;
  return response.data;
};
export const GetAllSchoolsPageable = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/school/pageable",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return;
  return response.data;
};
export const UpdateSchool = async (data) => {
  const response = await httpService({
    method: "post",
    url: BASE_URL + "/api/v1/school/update",
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
    options: {
      showErrorMessageFromServer: true,
      successMessage: "School Updated!",
    },
  });
  if (!response) return false;
  return true;
};
export const DeleteSchool = async (data) => {
  const response = await httpService({
    method: "delete",
    url: BASE_URL + "/api/v1/school/" + data,
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
      successMessage: "School Deleted!",
    },
  });
  if (!response) return false;
  return true;
};
