import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Input,
  message,
  Modal,
  Select,
  Table,
  Tooltip,
} from "antd";
import Link from "antd/lib/typography/Link";
import React, { useState } from "react";
import { GetSearchedCompanies } from "../../../services/company";
import "./countryInfo.scss";

const AgencyModal = (props) => {
  const {
    agencyList,
    setAgencyList,
    countryValues,
    isModalOpen,
    setIsModalOpen,
  } = props;

  const [searchResults, setSearchResults] = useState([]);
  const [webUrl, setWebUrl] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isValid = () => {
    const isCompanyAdded = agencyList.some((item) =>
      selectedCompany?.id
        ? item?.companyId == selectedCompany?.id
        : item?.companyName == selectedCompany?.name
    );

    if (agencyList.length >= 5) {
      message.error("Max 5 companies allowed");
      return false;
    }

    if (!selectedCompany || !webUrl.length > 0) {
      message.error("Fields must be filled");
      return false;
    }

    if (isCompanyAdded) {
      message.error("Company already added");
      return false;
    }

    return true;
  };

  const handleAddList = () => {
    if (!isValid()) {
      return;
    }

    setAgencyList([
      ...agencyList,
      {
        countryId: countryValues?.id,
        companyId: selectedCompany?.id,
        companyName: selectedCompany?.name,
        logo: selectedCompany?.logo,
        webUrl: webUrl,
      },
    ]);

    setSelectedCompany(null);
    setWebUrl("");
    setSearchValue("");
    setSearchResults([]);
  };

  const handleDelete = (id, name) => {
    const newData = agencyList.filter((item) =>
      id ? item.companyId !== id : name !== item.companyName
    );
    setAgencyList(newData);
  };

  const handleSelectCompany = (option) => {
    setSelectedCompany({
      id: option?.id || null,
      name: option?.value || searchValue,
      logo: option?.logo || null,
    });
  };

  const handleAddCompany = () => {
    if (searchValue.length > 0) {
      setDropdownOpen(false);
      handleSelectCompany();
    }
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      width: "50%",
      key: "companyId",
      render: (name, record) => (
        <div className="d-flex gap-2 align-items-center" style={{ height: 25 }}>
          <img
            style={{ borderRadius: 10, width: 25 }}
            src={`data:image/jpeg;base64,${record.logo}`}
          />
          {name}
        </div>
      ),
    },
    {
      title: "URL",
      dataIndex: "webUrl",
      width: "50%",
      key: "webUrl",
      render: (url) => (
        <Link href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </Link>
      ),
    },
    {
      title: "",
      dataIndex: "operation",
      key: "operation",
      render: (_, record) => (
        <Tooltip placement="top" title="Delete">
          <Button
            type="default"
            className="rounded-2"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.companyId, record.companyName)}
          />
        </Tooltip>
      ),
    },
  ];

  const getSearchedCompanies = async (params) => {
    const response = await GetSearchedCompanies(params);
    const options = response.map((item) => ({
      key: item.id,
      value: item.name,
      label: (
        <div className="d-flex gap-2 align-items-center" style={{ height: 30 }}>
          <img
            style={{ borderRadius: 10, width: 25 }}
            src={`data:image/jpeg;base64,${item.logo}`}
          />
          {item.name}
        </div>
      ),
      logo: item.logo,
      id: item.id,
    }));
    setSearchResults(options);
  };

  const handleSearch = (param) => {
    setSearchValue(param);
    param.length > 2 && getSearchedCompanies(param);
  };

  return (
    <Modal
      open={isModalOpen}
      footer={false}
      onCancel={() => setIsModalOpen(false)}
      className="w-50"
    >
      <div className="pt-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column w-75">
            <Select
              className="agencySelectBox"
              open={dropdownOpen}
              onDropdownVisibleChange={(open) => setDropdownOpen(open)}
              showSearch
              onSearch={(e) => handleSearch(e)}
              onChange={(value, option) => handleSelectCompany(option)}
              value={selectedCompany ? selectedCompany.name : null}
              placeholder="Search Company"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider className="m-2" />
                  <div className="p-1">
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={handleAddCompany}
                    >
                      Add Company
                    </Button>
                  </div>
                </>
              )}
              options={searchResults}
            />
            <Input
              placeholder="Enter Web Url"
              className="mt-2 rounded-3"
              allowClear
              value={webUrl}
              onChange={(e) => setWebUrl(e.target.value)}
            ></Input>
          </div>

          <div className="d-flex align-items-end">
            <Button
              type="primary"
              className="rounded-3 h-50"
              style={{ width: 100 }}
              onClick={handleAddList}
            >
              Add to List
            </Button>
          </div>
        </div>
        <div className="mt-4">
          <Table
            columns={columns}
            dataSource={agencyList}
            pagination={false}
          ></Table>
        </div>
      </div>
    </Modal>
  );
};

export default AgencyModal;
