import { Button, Input, Modal, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import removeIcon from "../../assets/images/remove-icon.png";
import { AddProfession, UpdateProfession } from "../../services/profession";

const SkillsModal = ({
  isUpdate,
  isOpen,
  setIsOpen,
  mainSkills,
  selectedProfession,
}) => {
  const [inputSkills, setInputSkills] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [professionName, setProfessionName] = useState(
    selectedProfession?.name
  );

  const handleAddProfession = async () => {
    const profession = {
      name: professionName,
      mainSkillList: selectedSkills,
    };
    if (professionName && selectedSkills.length > 0) {
      await AddProfession(profession);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      console.log("Empty field!");
    }
  };

  const handleUpdate = async () => {
    const profession = {
      id: selectedProfession.id,
      name: professionName,
      mainSkillList: selectedSkills,
    };
    if (professionName && selectedSkills.length > 0) {
      await UpdateProfession(profession);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      console.log("Empty field!");
    }
  };

  useEffect(() => {
    const filteredSkills = mainSkills
      .filter((skill) =>
        skill.mainSkillName.toLowerCase().includes(inputSkills.toLowerCase())
      )
      .filter(
        (skill) =>
          !selectedSkills?.some(
            (selected) => selected.mainSkillId === skill.mainSkillId
          )
      );
    setFilteredSkills(filteredSkills);
  }, [inputSkills, selectedSkills]);

  const handleAddTag = (skill) => {
    setSelectedSkills([...selectedSkills, skill]);
  };

  const handleRemoveTag = (skill) => {
    setSelectedSkills(
      selectedSkills.filter(
        (selected) => selected.mainSkillId !== skill.mainSkillId
      )
    );
  };

  useEffect(() => {
    const initialSkills = selectedProfession?.mainSkills?.map((item) => ({
      mainSkillId: item.mainSkillId,
      mainSkillName: item.mainSkillName,
    }));
    setSelectedSkills(initialSkills || []);
    setProfessionName(selectedProfession?.name);
  }, [selectedProfession]);

  return (
    <div>
      <Modal
        width={"30%"}
        open={isOpen}
        footer={null}
        centered
        closable={false}
        onCancel={() => setIsOpen(false)}
      >
        <Input
          placeholder="Profession"
          value={professionName}
          onChange={(e) => setProfessionName(e.target.value)}
          size="large"
          style={{
            marginBlock: 20,
            borderRadius: 6,
          }}
        />
        <Input
          placeholder="Main Skills"
          size="large"
          style={{ borderRadius: 6 }}
          value={inputSkills}
          onChange={(e) => setInputSkills(e.target.value)}
          allowClear
        />
        <div
          style={{
            maxHeight: 260,
            overflowY: "auto",
            marginTop: 10,
            marginRight: 24,
            width: "-webkit-fill-available",
            position: "absolute",
            backgroundColor: "white",
          }}
        >
          {inputSkills?.length > 0 &&
            filteredSkills.map((skill) => (
              <div key={skill.id} style={{ padding: "2px 0" }}>
                <Tag className="tag-style" onClick={() => handleAddTag(skill)}>
                  {skill.mainSkillName}
                </Tag>
              </div>
            ))}
        </div>
        <div
          className="selected-skills-container"
          style={{ marginTop: 20, overflowY: "scroll", height: 250 }}
        >
          {selectedSkills?.length > 0 &&
            selectedSkills.map((skill) => (
              <Tag
                key={skill.id}
                style={{ margin: 0 }}
                className="tag-style selected-tag"
                onClick={() => handleRemoveTag(skill)}
              >
                <span style={{ paddingRight: 5 }}>{skill.mainSkillName} </span>

                <img src={removeIcon} width={8} alt="remove"></img>
              </Tag>
            ))}
        </div>

        <Row justify={"space-between"} style={{ padding: 10, marginTop: 30 }}>
          <Button
            style={{ height: 40, width: 100 }}
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
          <Button
            type="primary"
            disabled={!professionName || selectedSkills.length == 0}
            style={{ height: 40, width: 100 }}
            onClick={isUpdate ? handleUpdate : handleAddProfession}
          >
            {isUpdate ? "Update" : "Add"}
          </Button>
        </Row>
      </Modal>
    </div>
  );
};

export default SkillsModal;
