import React, { useState, useEffect } from "react";
import { Layout, Menu, Button, message } from "antd";
import _ from "lodash";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import "./index.scss";

import { Routes, Route, Link, NavLink } from "react-router-dom";
import history from "../../helpers/history";

import NotFound from "../../modules/Common/NotFound";

import Modules from "../../modules";
import AppLogo from "../../assets/images/app-logo-w.png";
import AppLogo2 from "../../assets/images/app-logo-small.svg";
import { Agreement } from "../../modules/Agreement";
import { appStore } from "../../stores/appStore";

const { Header, Content, Footer, Sider } = Layout;

const MasterLayout = ({ username, claims }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [Pages, setPages] = useState([]);
  const [currentPageTitle, setCurrentPageTitle] = useState(null);

  const setActivePage = (pathname) => {
    const findCurrentPage = Modules.find((x) => x.path === pathname);
    if (findCurrentPage) {
      setCurrentPageTitle(findCurrentPage.title);
      document.title = `${findCurrentPage.title} - TalentScore`;
    }
  };

  history.listen((location) => {
    setActivePage(location.pathname);
  });

  useEffect(() => {
    const userPages = Modules;
    // .filter(
    //  (item) =>
    //  item.claims.some((x) => claims.includes(x))
    // );
    setPages(userPages);
    setActivePage(history.location.pathname);

    if (
      (window.location.pathname === "/" ||
        window.location.pathname === "/login") &&
      userPages &&
      userPages.length > 0
    ) {
      history.push(userPages[0].path);
    }
  }, []);

  const handleLogout = () => {
    appStore.dispatch("logout");
  };

  return (
    <Layout className="private-layout">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="logo">
          <Link to="/">
            <img
              className="menu-open"
              src={AppLogo}
              alt="Gini Talent"
              style={{ width: "150px" }}
            />
            <img className="menu-close" src={AppLogo2} alt="Gini Talent" />
          </Link>
        </div>
        <div
          className="menu"
          style={{
            textAlign: collapsed ? "center" : "",
          }}
        >
          {Pages.map((item, index) => {
            return (
              <NavLink
                key={index}
                exact
                to={item.path.replace(":id", "0").replace(":type", "0")}
              >
                {item.icon} {collapsed ? "" : item.title}
              </NavLink>
            );
          })}
        </div>
      </Sider>
      <Layout className={collapsed ? "site-layout collapsed" : "site-layout"}>
        <Header
          className="site-layout-background site-header"
          style={{ padding: 0 }}
        >
          <div
            className="menu-collapse"
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
          {currentPageTitle}
          <div className="right-section">
            <div className="username">{username}</div>
            <Button
              type="link"
              icon={<LogoutOutlined />}
              style={{ width: "35px" }}
              onClick={() => handleLogout()}
            />
          </div>
        </Header>
        <Content>
          <Routes>
            {Pages.map((item, index) => {
              const { component, ...rest } = item;
              const Component = component;
              return <Route key={index} {...rest} element={<Component />} />;
            })}
            <Route key="not-found" element={<NotFound />}></Route>
          </Routes>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          &copy; Gini Talent 2022
          <br />
          <Agreement />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MasterLayout;
