/* eslint-disable import/no-anonymous-default-export */
import * as _ from "lodash";
import utils from "./utils";

let basefolder = "/";
if (process.env.NODE_ENV === "production" && process.env.REACT_APP_PUBLIC_URL) {
  const hostname = utils.getHostNameOfUrl(process.env.REACT_APP_PUBLIC_URL);
  basefolder = _.split(process.env.REACT_APP_PUBLIC_URL, hostname, 2)[1];
}

export const DefaultSettings = {
  shortDateFormat: "DD/MM/YYYY",
  dateFormat: "DD MMMM YYYY",
  dateTimeFormat: "DD MMMM YYYY HH:mm",
};

export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const BASE_PATH = basefolder;
export const TOKEN = localStorage.getItem("token");


//TEST ve PROD ortamları içi recaptchaKEY
export const recaptchaSiteKey = '6LddZwgoAAAAAD-asdJM4KkFbrDOm5RmcmQFMPl5';
//export const BASE_URL = "https://test-app.talentscore.io/server";

//PROD ortamı için BASE_URL
export const BASE_URL = "https://app.talentscore.io/server";

//LOCAL ortamı için recaptchaKEY
//export const recaptchaSiteKey = '6LdRwBcoAAAAAJ0Tb7RLBRJBesO5KlWrdeV8dYmG';

export default {
  API_BASE_URL,
  DefaultSettings,
};