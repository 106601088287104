import React from 'react';

export const Agreement = () => {
	return (
		<React.Fragment>
			<a href="/agreements/Gini_Kullanim_Kosullari.html" target="_blank" className="more-info">
				Kullanım Koşulları
			</a>
			{' |  '}
			<a href="/agreements/Gini_KVK_Aydinlatma_Metni.html" target="_blank" className="more-info">
				KVK Aydınlatma Metni
			</a>
		</React.Fragment>
	);
};
