import React, { useState } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { PasswordReset } from '../../../services/security';

const ForgotPassword = () => {
	const [ form ] = Form.useForm();

	const handleSubmit = async (values) => {
		await PasswordReset(values);
		Modal.success({
			content: values.email + ' adresinize şifre yenileme linki gönderilmiştir. Lütfen mailinizi kontrol ediniz.'
			// onOk: () => (window.location.href = '/')
		});
	};

	return (
		<React.Fragment>
			<div className="wrapper-loggin">
				<div className="col-login col-login_left">
					<h2>Parolamı Unuttum</h2>
					<Form layout="vertical" form={form} onFinish={handleSubmit}>
						<Form.Item
							label=""
							name="email"
							rules={[ { required: true, message: 'Lütfen bu alanı doldurunuz' } ]}
						>
							<Input placeholder="e-Posta adresi" size="large" prefix={<UserOutlined />} />
						</Form.Item>

						<Form.Item>
							<Form.Item className="btn-login">
								<Button type="primary" size="large" htmlType="submit">
									Parolamı Sıfırla
								</Button>
							</Form.Item>
						</Form.Item>

						<p className="help-desk">
							Yardıma mı ihtiyacınız var? Bize ulaşın:{' '}
							<a href={'mailto:destek@ginifinance.com'}>destek@ginifinance.com</a>
						</p>
					</Form>
				</div>
				<div className="col-login col-login_right">
					<h3>
					Gini Talent is a global staffing and recruitment agency in software development business 
					and recruits the right talent for what the position requires.
					</h3>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ForgotPassword;
