import React, { useState, useEffect } from "react";
import { Row, Table, Input, Select, Button, Checkbox } from "antd";
import { DeleteSchool, GetAllSchoolsPageable } from "../../services/school";
import CompanyLogo from "../../assets/images/company-logo.png";
import linkedinIcon from "../../assets/images/linkedin.png";
import SchoolDrawer from "./drawer";
import { ClipLoader } from "react-spinners";
import { SortObjectsAdd } from "./../../utils";
import { GetCountries } from "../../services/utils";
import { css } from "@emotion/react";
import SchoolIcon from "../../assets/svg/school.svg";

const { Option } = Select;
const cssOverride = css`
  border-width: 4px;
`;

const Schools = () => {
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState();
  const [school, setSchool] = useState({
    data: [],
    pagination: {
      page: 0,
      size: 10,
      sort: [],
      name: null,
      country: null,
      schoolType: null,
      countryRankIsNull: false,
      globalRankIsNull: false,
      countryIsNull: false,
      englishLanguageIsNull: false,
      englishLanguage: false,
      noteIsNull: false,
      note: false,
    },
    totalElements: 0,
  });

  const [drawerDetail, setDrawerDetail] = useState({
    visible: false,
    close: (refresh) => {
      if (refresh) {
        refreshAllData();
      }
      setDrawerDetail((prev) => ({
        ...prev,
        visible: false,
      }));
    },
    countries: countries,
  });

  useEffect(() => {
    getData(school.pagination);
    getCountry();
  }, []);

  useEffect(() => {
    setDrawerDetail((prev) => ({
      ...prev,
      close: (refresh) => {
        if (refresh) {
          refreshAllData();
        }
        setDrawerDetail((prev) => ({
          ...prev,
          visible: false,
        }));
      },
      countries: countries,
    }));
  }, [school.pagination]);

  useEffect(() => {
    setColumns([
      {
        width: "100px",
        title: "Logo",
        key: "0",
        dataIndex: "logo",
        render: (text) =>
          text !== null && text && text.substring(0, 2) !== "77" ? (
            <a>
              <img
                src={`data:image/jpeg;base64,${text}`}
                style={{ width: "50px" }}
              ></img>
            </a>
          ) : (
            <a>
              <img
                src={CompanyLogo}
                alt="companyLogo"
                style={{ width: "50px" }}
              />
            </a>
          ),
      },
      {
        width: "400px",
        title: "School Name",
        dataIndex: "name",
        key: "1",
        sorter: {
          compare: (a, b) => a.name - b.name,
          multiple: 4,
        },
        render: (text) => <p className="text-ellipsis">{text}</p>,
      },
      {
        width: "100px",
        title: "Linkedin",
      },
      {
        width: "150px",
        title: "Country",
        dataIndex: "country",
        key: "2",
      },
      {
        width: "150px",
        title: "Country Rank",
        dataIndex: "countryRank",
        key: "3",
        sorter: {
          compare: (a, b) => a.countryRank - b.countryRank,
          multiple: 3,
        },
      },
      {
        width: "150px",
        title: "Global Rank",
        dataIndex: "globalRank",
        key: "4",
        sorter: {
          compare: (a, b) => a.globalRank - b.globalRank,
          multiple: 2,
        },
      },
      {
        width: "80px",
        title: "Score",
        dataIndex: "score",
        key: "5",
        sorter: {
          compare: (a, b) => a.score - b.score,
          multiple: 1,
        },
      },
    ]);
  }, []);

  const getCountry = async () => {
    const response = await GetCountries();
    if (response) {
      setCountries(response);
    }
  };

  const getData = async (params) => {
    setIsLoading(true);
    const response = await GetAllSchoolsPageable(params);
    setSchool((prevState) => ({
      ...prevState,
      data: response.data,
      pagination: {
        ...prevState.pagination,
        page: response.pageNumber,
        size: params.size,
        sort: params.sort,
      },
      totalElements: response.totalElements,
    }));
    setIsLoading(false);
  };

  const setSchoolPagination = (propsName, value) => {
    setSchool((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        [propsName]: value,
      },
    }));
  };

  const handleTableChange = (pagination, {}, sorter, {}) => {
    window.scrollTo(0, 0);
    let newSortObjects = SortObjectsAdd(sorter);
    getData({
      ...school.pagination,
      page: pagination.current - 1,
      size: pagination.pageSize,
      sort: newSortObjects,
    });
  };

  const refreshAllData = () => {
    getData(school.pagination);
  };

  const handleSchoolDetail = async (record) => {
    setDrawerDetail((prev) => ({
      ...prev,
      visible: true,
      type: "detail",
      id: record.id,
      initialValues: record,
      title: `School Edit`,
      handleDeleteSchool: () => {
        handleDeleteSchool(record);
      },
      countries: countries,
    }));
  };
  const handleDeleteSchool = (record) => {
    DeleteSchool(record.id).then(() => {
      refreshAllData();
      setDrawerDetail((prev) => ({
        ...prev,
        visible: false,
        countries: countries,
      }));
    });
  };

  const handleSearch = () => {
    getData({
      ...school.pagination,
      page: 0,
    });
  };

  const handleClearFilters = () => {
    setSchool((prevState) => ({
      ...prevState,
      pagination: {
        page: 0,
        size: 10,
        sort: [],
        name: null,
        country: null,
        schoolType: null,
        countryRankIsNull: false,
        globalRankIsNull: false,
        countryIsNull: false,
        englishLanguageIsNull: false,
        englishLanguage: false,
        noteIsNull: false,
        note: false,
      },
    }));
    getData({
      page: 0,
      size: 10,
      sort: [],
      name: null,
      country: null,
      schoolType: null,
      countryRankIsNull: false,
      globalRankIsNull: false,
      countryIsNull: false,
      englishLanguageIsNull: false,
      englishLanguage: false,
      noteIsNull: false,
      note: false,
    });
  };

  const { data, totalElements, pagination } = school;

  const handleSubmit = (text, linkedinPath) => {
    let win;
    if (linkedinPath !== null) {
      win = window.open("https://www.linkedin.com/" + linkedinPath, "_blank");
    } else {
      win = window.open(
        "https://www.linkedin.com/school/" + text + "/?legacySchoolId=" + text,
        "_blank"
      );
    }
    win.focus();
  };

  return (
    <div>
      <Row className="tables">
        <div className="filter-view">
          <div className="filter-entries">
            <div style={{ width: "180px", marginRight: "15px" }}>
              <Input
                placeholder="name"
                size="large"
                value={school.pagination.name}
                onChange={(e) => setSchoolPagination("name", e.target.value)}
              />
            </div>
            <div style={{ width: "180px", marginRight: "15px" }}>
              <Select
                allowClear
                size="large"
                showSearch
                placeholder="country"
                optionFilterProp="children"
                value={school.pagination.country}
                onChange={(value) => setSchoolPagination("country", value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {typeof countries === "object" &&
                  countries.length > 0 &&
                  countries.map((item, key) => {
                    return (
                      <Option value={item} key={key}>
                        {item}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div style={{ width: "180px", marginRight: "15px" }}>
              <Select
                allowClear
                size="large"
                showSearch
                placeholder="school type"
                optionFilterProp="children"
                value={school.pagination.schoolType}
                onChange={(value) => setSchoolPagination("schoolType", value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={"HIGH_SCHOOL"} key={0}>
                  High School
                </Option>
                <Option value={"UNIVERSITY"} key={1}>
                  University
                </Option>
                <Option value={"VOCATIONAL"} key={2}>
                  Vocational
                </Option>
                <Option value={"INSTITUTE"} key={3}>
                  Institute
                </Option>
              </Select>
            </div>
            <div>
              <Checkbox
                onChange={(e) => setSchoolPagination("note", e.target.checked)}
                checked={school.pagination.note}
              >
                Note
              </Checkbox>
            </div>
            <div>
              <Checkbox
                onChange={(e) =>
                  setSchoolPagination("englishLanguage", e.target.checked)
                }
                checked={school.pagination.englishLanguage}
              >
                Instruction Language: English
              </Checkbox>
            </div>
          </div>
          <div className="filter-checked">
            <div className="checked-area">
              <div className="checked-area-title"> Is Null ?</div>
              <Checkbox
                onChange={(e) =>
                  setSchoolPagination("countryIsNull", e.target.checked)
                }
                checked={school.pagination.countryIsNull}
              >
                Country
              </Checkbox>
              <Checkbox
                onChange={(e) =>
                  setSchoolPagination("countryRankIsNull", e.target.checked)
                }
                checked={school.pagination.countryRankIsNull}
              >
                Country Rank
              </Checkbox>
              <Checkbox
                onChange={(e) =>
                  setSchoolPagination("globalRankIsNull", e.target.checked)
                }
                checked={school.pagination.globalRankIsNull}
              >
                Global Rank
              </Checkbox>
              <Checkbox
                onChange={(e) =>
                  setSchoolPagination("englishLanguageIsNull", e.target.checked)
                }
                checked={school.pagination.englishLanguageIsNull}
              >
                English Language
              </Checkbox>
              <Checkbox
                onChange={(e) =>
                  setSchoolPagination("noteIsNull", e.target.checked)
                }
                checked={school.pagination.noteIsNull}
              >
                Note
              </Checkbox>
            </div>
          </div>
        </div>
        <div className="filter-button">
          <Button
            style={{ width: "120px" }}
            type="default"
            size="large"
            htmlType="submit"
            onClick={handleClearFilters}
          >
            Clear
          </Button>
          <Button
            style={{ width: "120px" }}
            type="primary"
            size="large"
            htmlType="submit"
            onClick={handleSearch}
          >
            Filter
          </Button>
        </div>

        {columns && (
          <Table
            scroll={{ x: "%100" }}
            columns={columns}
            dataSource={data}
            style={{
              width: "100%",
              textAlignLast: "center",
              cursor: "pointer",
            }}
            rowKey="id"
            footer={() => "Showing of " + totalElements + " entries "}
            tableLayout="fixed"
            onChange={handleTableChange}
            pagination={{
              total: totalElements,
              current: pagination.page + 1,
              size: pagination.size,
              pageSize: pagination.size,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (event.target.id === "linkedin") {
                  } else {
                    handleSchoolDetail(record);
                  }
                },
                onMouseEnter: (event) => {
                  let newColumns = [...columns];
                  newColumns[2] = {
                    width: "100px",
                    title: "Linkedin",
                    dataIndex:
                      record.linkedinRef !== null
                        ? "linkedinRef"
                        : "linkedinSecondaryRef",
                    key:
                      record.linkedinRef !== null
                        ? "linkedinRef"
                        : "linkedinSecondaryRef",
                    render: (text, record, index) => {
                      if (index === rowIndex) {
                        return (
                          <p
                            className="text-ellipsis"
                            onClick={() =>
                              handleSubmit(text, record.linkedinPath)
                            }
                          >
                            <img
                              id="linkedin"
                              src={linkedinIcon}
                              alt="linkedin"
                              width={16}
                              height={16}
                            />
                          </p>
                        );
                      }
                    },
                  };
                  setColumns(newColumns);
                },
              };
            }}
          />
        )}
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ClipLoader
              color={"#03283a"}
              loading={isLoading}
              size={60}
              css={cssOverride}
            />
          </div>
        )}
        <SchoolDrawer {...drawerDetail} />
      </Row>
    </div>
  );
};

const PageIcon = () => {
  return (
    <img
      src={SchoolIcon}
      alt="logo"
      style={{
        height: 20,
        width: 20,
      }}
    />
  );
};

export default {
  title: "Schools",
  icon: <PageIcon />,
  path: "/Schools",
  claims: ["Schools"],
  component: Schools,
};
