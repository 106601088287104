import httpService from "../helpers/httpService";
import { BASE_URL, TOKEN } from "../helpers/constants";

export const GetLogs = async () => {
  const response = await httpService({
    method: "get",
    url: BASE_URL + "/api/v1/user-note/file/log/",
    headers: { Authorization: `Bearer ${TOKEN}` },
    options: {
      showErrorMessageFromServer: true,
    },
  });
  if (!response) return [];
  return response.data.data;
};
